export const removeNonValueFilter = (filterValues) => {
    const filters = {}
    Object.keys(filterValues).forEach(key => {
        if (filterValues[key]) {
            Object.assign(filters, { [key]: filterValues[key] })
        }
    })
    return filters
}

export const random = (max) => {
    return Math.floor(Math.random() * max)
}

export const makeid = (length, prefix, shard = 'AA', seprator = '-') => {
    let result = `${shard}${prefix}`;
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const charLen = characters.length;
    for (let i = 0; i < length; i++) {
        if (i % 4 === 0) {
            result += seprator;
        }
        result += characters.charAt(random(charLen));

    }
    return result.toLowerCase();
}

const indiaTimeZone = 'Asia/Kolkata';
const qatarTimeZone = 'Asia/Qatar';

export const getToday = () => {
    const now = new Date();
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return now.toLocaleDateString('en-IN', options);
};

export const getQatarToday = () => {
    const now = new Date();
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return now.toLocaleDateString('en-QA', options);
};

export const getPriorDate = (nprev_days) => {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - nprev_days));
    return priorDate
  }

export const restrictFutureDates = () =>  {
    var yesterday = getPriorDate(1)
    // minimum date the user can choose, in this case now and in the future
    const maxDate = yesterday.toISOString().substring(0, 10);
    return maxDate
  }

export const meterGroupDisplayOrder = [
    'water_quantity', 'water_quality', 'energy', 'equipment_status', 'pressure', 'level', 'air_quality', 'temperature',
]

export const generateInsightKey = (...args ) => {
const key = args.join('-')
return `g-${key}`
}  

export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const STP_WATER_BALANCE_TAGS = ['STP Source', 'STP Treatment', 'STP Flushing Consumption', 'STP Garden Consumption', 'STP Cooling Tower Consumption']
export const STP_ENERGY_TAGS = ['STP Energy', 'STP Treatment']
export const STP_OPEX_COST_TAGS = ['STP Energy', 'STP Treatment']
export const STP_WQPI_TAGS = ['STP - pH', 'STP - TDS', 'STP - ORP', 'STP - TBD', 'STP - TSS', 'STP - BOD', 'STP - COD']

export const WTP_WATER_BALANCE_TAGS = ['WTP Treatment', 'WTP Source', 'WTP Domestic Consumption', 'WTP Garden Consumption', 'WTP Cooling Tower Makeup']
export const WTP_ENERGY_TAGS = ['WTP Energy', 'WTP Treatment']
export const WTP_WQPI_TAGS = ['WTP pH','WTP TDS', 'WTP ORP']

export const ETP_WATER_BALANCE_TAGS = ['ETP Treatment', 'ETP Source', 'ETP Garden Consumption', 'ETP Flushing Consumption']
export const ETP_ENERGY_TAGS = ['ETP Energy', 'ETP Treatment']
export const ETP_OPEX_COST_TAGS = ['ETP Energy', 'ETP Treatment']