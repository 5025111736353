import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getFacilityById, getSiteVisitReportById } from '../../Redux/actions';
import moment from 'moment';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { CircularProgress } from '@mui/material';

import BackArrow from '../../Images/BackArrow.png';
// import GreenvironmentLogo from '../../../src/Images/Greenvironment-logo.svg'
import GreenvironmentLogo from '../../../src/Images/Greenvironment-logo-wbg.svg'
export default function SiteVisitReport() {
    const { id, client_id, pid, facilityId } = useParams();
    const [siteVisitData, setSiteVisitData] = useState();
    const [showLoader, setShowLoader] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const selectedItem = location?.state?.selectedItem;

    const [siteVisitNotFound, setSiteVisitNotFound] = useState(false);
    const [facilityNotFound, setFacilityNotFound] = useState(false);

    useEffect(() => {
        setShowLoader(true);
        dispatch(getSiteVisitReportById(client_id, pid, id)).then(resp => {
            if (!resp?.data?.success) {
                setSiteVisitNotFound(true)
                return
            }
            setSiteVisitData(resp.data.data);
            setShowLoader(false);
        })
        // if (selectedItem) {
        //     setSiteVisitData(selectedItem)
        //     setShowLoader(false);
        // }
        if(facilityId) {
            dispatch(getFacilityById(client_id,facilityId)).then(resp => {
                const response = resp?.data?.facilityData
                if (!response) {
                    setFacilityNotFound(true);
                    return;
                }
            })
        }
    }, [selectedItem])

    const handleDownloadReport = () => {
        window.print()
    }


    let siteVisitReport;
    if(id && siteVisitNotFound) {
        return <Grid container >
        <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
            <h5 className='w3-text-grey'>
                Site visit report not found
            </h5>
        </Grid>
    </Grid>
    }
    if (showLoader) {
        siteVisitReport = (<Grid container>
            <Grid item align="center">
                <CircularProgress />
            </Grid>
        </Grid>)
    } else if (!siteVisitData) {
        siteVisitReport = (<Grid>
            <div style={{ display: 'grid', placeItems: 'center' }}>
                <h6 className="w3-text-grey w3-padding no-data-msg">No Data Found</h6>
            </div>
        </Grid>)
    } else {
        const tickets = siteVisitData?.ticketNumber;
            const ticketsArr = tickets?.split(',') || [];
            const ticketNumber = ticketsArr.filter(item => item !== null && !item.startsWith('null'));
        siteVisitReport = (<div className="site-visit-report-container">
            <Grid style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }}>
                <img id="back-arrow" className="cursor-pointer" width="20px" height="20px" onClick={() => navigate(-1)} src={BackArrow} alt="back-arrow" style={{ marginTop: '10px' }} />
                <Button
                    variant="contained"
                    id="download-site-visit-report-btn" onClick={() => handleDownloadReport()}>
                    Download Report
                </Button>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                    <img alt="green-logo" width="250px" height="70px" src={GreenvironmentLogo} />
                </Grid>
            </Grid>

            <div>
                <div>
                    <TableContainer style={{ border: '1px solid rgba(224, 224, 224, 1)' }} sx={{ mb: 4, mt: 4 }}>
                        <Table>
                            <TableHead >
                                <TableRow>
                                    <TableCell style={{ backgroundColor: '#64d001', fontSize: '15px' }} className="w3-center" colSpan={12}><b>{`${siteVisitData.siteVisitType} Report`}</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableRow>
                                <TableCell style={{ width: '50%', borderRight: '1px  solid rgba(224, 224, 224, 1)' }} colSpan={8}><b>Client Name</b></TableCell>
                                <TableCell colSpan={4}>{siteVisitData?.clientName}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: '1px  solid rgba(224, 224, 224, 1)' }} colSpan={8}><b>Project Name</b></TableCell>
                                <TableCell colSpan={4}>{siteVisitData?.project?.projname}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: '1px  solid rgba(224, 224, 224, 1)' }} colSpan={8}><b>Site Visit Type</b></TableCell>
                                <TableCell colSpan={4}>{siteVisitData?.siteVisitType}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: '1px  solid rgba(224, 224, 224, 1)' }} colSpan={8}><b>Ticket Number</b></TableCell>
                                <TableCell colSpan={4}>{ticketNumber?.length > 0 ? ticketNumber.join(',') : 'NA'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: '1px  solid rgba(224, 224, 224, 1)' }} colSpan={8}><b>Engineers In charge</b></TableCell>
                                <TableCell colSpan={4}>{siteVisitData?.engineerVisiting}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: '1px  solid rgba(224, 224, 224, 1)' }} colSpan={8}><b>Visited Date</b></TableCell>
                                <TableCell colSpan={4}>{moment(siteVisitData?.visitedDate).format('YYYY-MM-DD')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ borderRight: '1px  solid rgba(224, 224, 224, 1)' }} colSpan={8}><b>Reported Date</b></TableCell>
                                <TableCell colSpan={4}>{moment(siteVisitData?.reportTime).format('YYYY-MM-DD HH:mm')}</TableCell>
                            </TableRow>
                        </Table>
                        <Table>
                            <TableHead className="">
                                <TableRow>
                                    <TableCell style={{ backgroundColor: '#64d001', fontSize: '15px' }} className="w3-center" colSpan={12}><b>Report Summary</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableRow>
                                <TableCell style={{ borderRight: '1px  solid rgba(224, 224, 224, 1)', backgroundColor: '#64d001', fontSize: '15px' }} colSpan={1}><b>Sl No</b></TableCell>
                                <TableCell style={{ borderRight: '1px  solid rgba(224, 224, 224, 1)', backgroundColor: '#64d001', fontSize: '15px' }} colSpan={6}><b>Description</b></TableCell>
                                <TableCell style={{ borderRight: '1px  solid rgba(224, 224, 224, 1)', backgroundColor: '#64d001', fontSize: '15px' }} colSpan={1}><b>Responsible - Team</b></TableCell>
                                <TableCell style={{ backgroundColor: '#64d001', fontSize: '15px' }} colSpan={4}><b>Photo</b></TableCell>
                            </TableRow>
                            {siteVisitData?.dynamicFieldsData.map((dynamicField, idx) => {
                                return (
                                    <TableRow key={idx}>
                                        <TableCell style={{ width: '10%', borderRight: '1px solid rgba(224, 224, 224, 1)' }} colSpan={1}>{idx + 1}</TableCell>
                                        <TableCell style={{ width: '40%', borderRight: '1px solid rgba(224, 224, 224, 1)' }} colSpan={6}>{dynamicField.description}</TableCell>
                                        <TableCell style={{ width: '20%', borderRight: '1px solid rgba(224, 224, 224, 1)' }} colSpan={1}>{dynamicField.responsible_team}</TableCell>
                                        <TableCell style={{ width: '30%' }} colSpan={4}>
                                            {dynamicField.images.map((image, index) => (
                                                <img key={index} style={{paddingRight: '10px', paddingBottom: '10px'}} width="120px" alt={`${idx} pic`} src={image.signedUrl} />
                                            ))}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}



                        </Table>
                    </TableContainer>
                </div>
            </div>
        </div>
        )
    }

    if(facilityNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    Site visit report not found
                </h5>
            </Grid>
        </Grid>
    }

    return <Grid container>
        {siteVisitReport}
    </Grid>

}