/* eslint-disable import/no-anonymous-default-export */
export default {
    //Project Module

    createNewProject: {
        url: '/api/v1/project/create-project',
        method: 'POST'
    },
    getAllProject: {
        url: '/api/v1/project/get-all-project',
        storeKey:'allProjects',
        method: 'GET'
    },
    getProjectsFromFacility: {
        url: '/api/v1/project/get-projects-from-facility',
        method: 'GET'
    },
    getProjectById: {
        url: '/api/v1/project/get-project',
    },
    updateProject : {
        url: '/api/v1/project/update-project',
        method: 'POST'
    },
    deleteProject : {
        url: '/api/v1/project/delete-project',
        method: 'DELETE'
    }, 
    userProjectsList: {
        url: '/api/v1/project/get-user-projects',
    },
    createClient: {
        url: '/api/v1/client/create-client',
        method: 'POST'
    },
    getUserClientIntId: {
        url: '/api/v1/client/get-user-client-int-id',
    },
    allClients: {
        url: '/api/v1/client/get-clients'
    },
    getClientById: {
        url: '/api/v1/client/get-client'
    },
    getClientByUniqueId: {
        url: '/api/v1/client/get-client-by-uniqueId'
    },
    updateClient:  {
        url: '/api/v1/client/update-client',
        method: 'POST'
    },
    deleteClient : {
        url: '/api/v1/client/delete-client',
        method: 'DELETE'
    },
    //Meter Module
    createNewMeter: {
        url: '/api/v1/meter/create-meter',
        method: 'POST'
    },
    getAllMeter: {
        url: '/api/v1/meter/get-all-meter',
        method: 'GET'
    },
    getMeterById: {
        url: '/api/v1/meter/get-meter',
    },
    getOnlineMonitoringList: {
        url: '/api/v1/meter/get-online-monitoring-list',
        method: 'GET'
    },
    getOnlineMonitoringDetails: {
        url: '/api/v1/meter/get-online-monitoring-details',
        method: 'GET'
    },
    downloadOnlineMtrngList: {
        url: '/api/v1/meter/download-online-monitoring-list',
        method: 'GET'
    },
    updateOnlineMonitoringDetails: {
        url: '/api/v1/meter/update-online-monitoring-details',
        method: 'POST'
    },
    deleteOnlineMonitoring : {
        url: '/api/v1/meter/delete-online-monitoring',
        method: 'DELETE'
    },
    getMeterByMeterId: {
        url: '/api/v1/meter/get-meter-by-meter-id'
    },
    updateMeter : {
        url: '/api/v1/meter/update-meter',
        method: 'POST'
    },
    deleteMeter : {
        url: '/api/v1/meter/delete-meter',
        method: 'DELETE'
    },
    getWeeklySummaryTabs: {
        url: '/api/v1/meter/get-weekly-summary-tabs',
    },
    getMidsByFacilityId: {
        url: '/api/v1/meter/get-mids-by-facilityId',
        method: 'GET'
    },

    //Facility Module
    createNewFacility: {
        url: '/api/v1/facility/create-facility',
        method: 'POST'
    },

    allFacilities: {
        url: '/api/v1/facility/get-all-facilities',
        method: 'GET',
        storeKey:'allFacilities'
    },
    getFacilityById: {
        url: '/api/v1/facility/get-facility',
    },
    updateFacility : {
        url: '/api/v1/facility/update-facility',
        method: 'POST'
    },

    deleteFacility : {
        url: '/api/v1/facility/delete-facility',
        method: 'DELETE'
    },
    getAllFacilityStatus: {
        url: '/api/v1/facility/get-all-facility-status',
        method: 'GET',
        storeKey:'allFacilityStatus'
    },

    createLocation: {
        url: '/api/v1/location/create',
        method: 'POST'
    },
    getClientLocations: {
        url: '/api/v1/location/get-all-location',
        storeKey:'allLocations'
    },
    getClientLocationById: {
        url: '/api/v1/location/get-location',
        storeKey:'clientLocations'
    },
    updateLocation: {
        url: '/api/v1/location/update',
        method: 'POST'
    },
    deleteClientLocation: {
        url: '/api/v1/location/delete',
        method:'DELETE'
    },
    createInvite: {
        url: '/api/v1/invite/create',
        method: 'POST'
    },
    getAllInvite: {
        url: '/api/v1/invite/invited-data'
    },
    getJoinedGreenUsers: {
        url: '/api/v1/invite/joined-invite-data'
    },
    getAllClientUsers: {
        url: '/api/v1/invite/client-users'
    },
    deleteInvite: {
        url: '/api/v1/invite/delete',
        method: 'DELETE'
    },
    updateInvite: {
        url: '/api/v1/invite/update',
        method: 'POST'
    },
    updateJoinedUser: {
        url: '/api/v1/invite/update-joined-user',
        method: 'POST'
    },
    deactivateAuthUser: {
        url: '/api/v1/invite/deactivate-auth-user',
        method: 'POST'
    },
    activateAuthUser: {
        url: '/api/v1/invite/activate-auth-user',
        method: 'POST'
    },
    getUserInviteData: {
        url: '/api/v1/invite/user-invited-data'
    },
    getJoinedUserData: {
        url: '/api/v1/invite/joined-user-data'
    },
    getUserInviteById: {
        url: '/api/v1/invite/user'
    },
    getJoinedUserById: {
        url: '/api/v1/invite/joined-userById'
    },
    getInviteStatusByToken: {
        url: '/api/v1/invite/validate-token'
    },
    acceptInvite: {
        url: '/api/v1/invite/accept',
        method: 'POST'
    },
    //Auth
    register: {
        url: '/api/v1/auth/register',
        method: 'POST',
    },

    login: {
        url: '/api/v1/auth/login',
        method: 'POST',
    },

    forgotPassword: {
        url: '/api/v1/auth/forgot-password',
        method: 'POST',
    },

    resetPassword: {
        url: '/api/v1/auth/reset-password',
        method: 'POST',
    },

    tokenValidation: {
        url: '/api/v1/auth/token-validation',
        method: 'GET',
    },
    getSpocUser: {
        url: '/api/v1/invite/spoc'
    },
    resendInvite: {
        url: '/api/v1/invite/resend-invite',
        method: 'POST'
    },
    uploadMedia: {
        url: '/api/v1/client/upload',
        method: 'POST',
    },
    deleteClientMedia: {
        url: '/api/v1/client/delete-client-media',
        method: 'DELETE'
    },
    deleteMedia: {
        url: '/api/v1/client/delete-media',
        method: 'DELETE'
    },
    getInviteByToken: {
        url: '/api/v1/invite/invite-token-status'
    },
 
    // Report
    allReports: {
        url: '/api/v1/report/get-all-reports',
    },
    deletReport : {
        url: '/api/v1/report/delete-report',
        method: 'DELETE'
    },
    createNewReport: {
        url: '/api/v1/report/create-report',
        method: 'POST'
    },
    updateReport: {
        url: '/api/v1/report/edit-report',
        method: 'POST'
    },
    getReportById: {
        url: '/api/v1/report/get-report',
    },
    supersetLogin: {
        url: '/api/v1/report/superset-login',
        method: 'POST',
    }, 
    getGreenS3Data: {
        url: '/api/v1/report/get-green-s3-data',
        method: 'POST',
    },
    supersetDashboards: {
        url: '/api/v1/report/superset-dashboard-names',
    },

    // User Permissions
    getUsersByClientId: {
        url: '/api/v1/user-permission/get-users-by-client-id',
    },
    
    user: {
        url: '/api/v1/auth/user'
    },
    role: {
        url: '/api/v1/auth/role'
    },

    //Settings
    updateProfile: {
        url: '/api/v1/auth/update-profile',
        method: 'POST'
    },
    changeUserPassword: {
        url: '/api/v1/auth/user-password',
        method: 'PUT'
    },

    //Meter Tags
    getAllMeterTags: {
        url: '/api/v1/meter/get-all-meter-tags',
        method: 'GET',
        storeKey: 'allMeterTags'
    },
    getAllMeterStatus: {
        url: '/api/v1/meter/get-all-meter-status',
        method: 'GET',
        storeKey: 'allMeterStatus'
    },
    primaryContact: {
        url: '/api/v1/client/get-client-primary-contact'
    },
    getMeterCalibration: {
        url: '/api/v1/meter/calibration'
    },
    getMeterCalibrationById: {
        url: '/api/v1/meter/calibration-details'
    },
    updateCalibrationReport: {
        url: '/api/v1/meter/update-calibration-report',
        method: 'POST'
    },
    deleteCalibrationReport: {
        url: '/api/v1/meter/delete-calibration-report',
        method: 'DELETE'
    },
    cloneCalibrationReport: {
        url: '/api/v1/meter/clone-calibration-report',
        method: 'POST'
    },
    //OpenAI Integration
    getOpenaiResponse: {
        url: '/api/v1/report/get-openai-response',
        method: 'POST',
    },

    getOpenaiResponseForImageUrl: {
        url: '/api/v1/report/get-openai-response-for-imageurl',
        method: 'POST',
    },

    createNewCalibrationReport: {
        url: '/api/v1/meter/create-calibration-report',
        method: 'POST'
    },

    //Tickets
    getAllTickets: {
        url: '/api/v1/client/get-all-tickets',
        method: 'GET'
    },
    getAllFacilityTickets: {
        url: '/api/v1/client/get-all-facility-tickets',
        method: 'GET'
    },
    getTicketById: {
        url: '/api/v1/client/get-ticket-by-id',
        method: 'GET'
    },
    getTicketsByAssignee: {
        url: '/api/v1/client/get-tickets-by-assignee',
        method: 'GET'
    },
    getAllAssignees: {
        url: '/api/v1/client/get-all-assignees',
        method: 'GET',
        storeKey:'allAssignees'
    },
    getAllTicketStatus: {
        url: '/api/v1/client/get-all-status',
        method: 'GET',
        storeKey:'allStatus'
    },
    getAllSiteVisitReport: {
        url: '/api/v1/project/get-all-site-visit-report',
        method: 'GET'
    },
    getSiteVisitReportById: {
        url: '/api/v1/project/get-site-visit-report-by-id',
        method: 'GET'
    },
    updateSiteVisitReport: {
        url: '/api/v1/project/update-site-visit-report',
        method: 'POST'
    },
    createNewSiteVisitReport: {
        url: '/api/v1/project/create-site-visit-report',
        method: 'POST'
    },
    getAllFacilitiesForTickets: {
        url: '/api/v1/facility/get-all-facilities-for-tickets',
        method: 'GET',
        storeKey:'allFacilitiesForTickets'
    },
    deleteSiteVisitReport: {
        url: '/api/v1/project/delete-site-visit-report',
        method: 'DELETE'
    },
    deleteSiteVisitReportImage: {
        url: '/api/v1/project/delete-site-visit-report-image',
        method: 'DELETE'
    },

    deleteProjectImage: {
        url:'/api/v1/project/delete-project-image',
        method: "DELETE"
    },

}