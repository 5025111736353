import { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Button, Card, CardContent, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import WaterConsumptionChart from './WaterConsumptionChart';
import WBTrendingAnalysisChart from './WBTrendingAnalysisChart';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from 'dayjs';
import {isValidDate} from '../../../utils/Common';

export default function SustainabilityDashboard({ waterBalanceReportList, client_id }) {
    const [showLoader, setShowLoader] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [waterBalanceTableData, setWaterBalanceTableData] = useState('');
    const [form, setForm] = useState({
        reportName: '',
        reportCategory: ''
    });
    const maxDate = dayjs().subtract(1, 'days').toDate()
    const [wbFromDate, setWbFromDate] = useState(
        dayjs().subtract(30, 'days').toDate()
    );
    const [wbToDate, setWbToDate] = useState(
        dayjs().subtract(1, 'days').toDate()
    );
    const [errors, setErrors] = useState([]);
    const [disableDownloadBtn, setDisableDownloadBtn] = useState(true);

    const fetchData = async (url, params = {}) => {
        try {
            const response = await axios.get(url, { params });
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            return null;
        }
    };

    const validateForm = (reportMedia, reportCategory) => {
        let formIsValid = true;
        let err = { ...errors };

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const fromDate = wbFromDate ? new Date(wbFromDate) : null;
        if (fromDate) {fromDate.setHours(0, 0, 0, 0);}

        const toDate = wbToDate ? new Date(wbToDate) : null;
        if (toDate) {toDate.setHours(0, 0, 0, 0);}


        if (!reportMedia) {
            formIsValid = false;
            err.reportName = 'Please select a report';
        }
        if (reportCategory === 'Water Balance Master') {
            if (!wbFromDate) {
                formIsValid = false;
                err.wbFromDate = 'Please select From Date';
            }

            if (!wbToDate) {
                formIsValid = false;
                err.wbToDate = 'Please select To Date';
            }

            if (moment(wbFromDate).format('YYYY-MM-DD') > moment(wbToDate).format('YYYY-MM-DD')) {
                formIsValid = false;
                err.wbFromDate = 'From Date should be less than To Date';
            }

            if (!isValidDate(wbFromDate)) {
                formIsValid = false;
                err.wbFromDate = 'Please enter a valid date in the format DD/MM/YYYY';
            }

            if (!isValidDate(wbToDate)) {
                formIsValid = false;
                err.wbToDate = 'Please enter a valid date in the format DD/MM/YYYY';
            }

            if(fromDate >= today) {
                formIsValid = false;
                err.wbFromDate = "From Date should be less than today's Date";
            }
    
            if(toDate >= today) {
                formIsValid = false;
                err.wbToDate = "To Date should be less than today's Date";
            }
        }
        setErrors(err);
        return formIsValid;
    }

    const handleGetReportData = async (reportMediaId, reportCategory, reportFromDate, reportToDate) => {
        setShowLoader(true);
        const valid = validateForm(reportMediaId, reportCategory);

        if (!valid) {
            setShowLoader(false);
            setChartData('<div style="margin:12px 0 20px 0; font-size: 16px">No Data Found</div>');
            return;
        }
        if (reportCategory === 'Water Balance Upload') {
            setChartData([]);
            const params = { media_id: reportMediaId, client_id };
            const response = await fetchData('/papi/v1/water-balance-table/', params);
            setWaterBalanceTableData(response || '<div style="margin:12px 0 20px 0; font-size: 16px">No Data Found</div>');
            if (response) {
                setDisableDownloadBtn(false)
            }
        } else if (reportCategory === 'Water Balance Master') {
            setWaterBalanceTableData('');
            const params = {
                client_id,
                from_date: moment(reportFromDate).format('YYYY-MM-DD'),
                to_date: moment(reportToDate).format('YYYY-MM-DD'),
                media_id: reportMediaId
            };
            const response = await fetchData('/papi/v1/get-water-balance-trend-data/', params);
            if (response?.bar_chart_data_list || response?.sunburt_chart_data_list) {
                setDisableDownloadBtn(false);
                setChartData(response || '<div style="margin:12px 0 20px 0; font-size: 16px">No Data Found</div>');
            }
        }
        setShowLoader(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'reportName') {
            const selectedOption = waterBalanceReportList.find(item => item.value === value);
            setForm(prevForm => ({
                ...prevForm,
                reportCategory: selectedOption?.category || ''
            }));
            const fromDate = dayjs().subtract(30, 'days').toDate()
            const toDate = dayjs().subtract(1, 'days').toDate()
            setWbFromDate(fromDate);
            setWbToDate(toDate);
            setDisableDownloadBtn(true)
        }
        setForm(prevForm => ({
            ...prevForm,
            [name]: value
        }));
        setErrors({})
    };

    const renderWaterBalanceGraph = () => {
        if (showLoader) {
            return <div className='w3-center' style={{ marginTop: 30 }}><CircularProgress /></div>;
        } else if (form.reportCategory === 'Water Balance Upload' && !disableDownloadBtn) {
            return <div style={{ marginTop: 22 }} dangerouslySetInnerHTML={{ __html: waterBalanceTableData }} />;
        } else if (form.reportCategory === 'Water Balance Master' && !disableDownloadBtn) {
            return <Grid container spacing={2} style={{ margin: '12px 0' }}>
                <Grid item md={5} sm={6}>
                    <Card>
                        <CardContent>
                            <div className="sub-title sunburst-title">Water Consumption {!disableDownloadBtn ? `(${moment(wbToDate).format('DD-MM-YYYY')})` : ''}</div>
                            <div id="water-balance-sunburst">
                                <WaterConsumptionChart
                                    tableData={chartData?.sunburt_chart_data_list}
                                    client_id={client_id}
                                    from_date={wbFromDate}
                                    to_date={wbToDate}
                                    media_id={form.reportName}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={7} sm={6}>
                    <Card>
                        <CardContent>
                            <div className="sub-title stacked-bar-title">Trending Analysis {!disableDownloadBtn ? `(From ${moment(wbFromDate).format('DD-MM-YYYY')} to ${moment(wbToDate).format('DD-MM-YYYY')})` : ''}</div>
                            <div id="water-balance-stacked-bar">
                                <WBTrendingAnalysisChart
                                    tableData={chartData?.bar_chart_data_list}
                                    chartSummaryPoints={chartData.stacked_bar_summary_points}
                                    client_id={client_id}
                                    from_date={wbFromDate}
                                    to_date={wbToDate}
                                    media_id={form.reportName}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        }
    };

    const disableSubmitBtn = !form.reportCategory || (form.reportCategory === 'Water Balance Master' && !form.reportName && !form.wbFromDate && !form.wbToDate) ? true : false;

    return (
        <div style={{ marginTop: 22 }}>
            <Grid container spacing={2} id='wb-container'>
                <Grid item>
                    <FormControl size='small' fullWidth required>
                        <InputLabel id="log-select-label">Select report</InputLabel>
                        <Select
                            fullWidth
                            labelId="select-report"
                            value={form.reportName}
                            label="Select report"
                            name="reportName"
                            onChange={e => handleChange(e)}
                        >
                            {waterBalanceReportList.map(item => <MenuItem value={item.value}> {item.label}</MenuItem>)}
                        </Select>
                        {errors.reportName && <div className='error-text w3-margin-bottom'>{errors.reportName}</div>}
                    </FormControl>
                </Grid>
                {form.reportCategory === 'Water Balance Master' && (
                    <>
                        <Grid item md={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
                                <DatePicker
                                    label="From Date *"
                                    dateFormat="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    required
                                    className='report-view-calendar-icon'
                                    slotProps={{ textField: { fullWidth: true, error: false, size: 'small' } }}
                                    name="wbFromDate"
                                    value={dayjs(wbFromDate)}
                                    maxDate={dayjs(maxDate)}
                                    onChange={(newValue) => {
                                        setWbFromDate(dayjs(newValue).toDate());
                                        setErrors({});
                                        setDisableDownloadBtn(true)
                                    }}
                                />
                            </LocalizationProvider>
                            {errors.wbFromDate && <div className='error-text w3-margin-bottom'>{errors.wbFromDate}</div>}
                        </Grid>
                        <Grid item md={3}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
                                <DatePicker
                                    label="To Date *"
                                    dateFormat="DD/MM/YYYY"
                                    format="DD/MM/YYYY"
                                    required
                                    className='report-view-calendar-icon'
                                    slotProps={{ textField: { fullWidth: true, error: false, size: 'small' } }}
                                    name="wbToDate"
                                    value={dayjs(wbToDate)}
                                    maxDate={dayjs(maxDate)}
                                    minDate={dayjs(wbFromDate)}
                                    onChange={(newValue) => {
                                        setWbToDate(dayjs(newValue).toDate());
                                        setErrors({});
                                        setDisableDownloadBtn(true)
                                    }}
                                />
                            </LocalizationProvider>
                            {errors.wbToDate && <div className='error-text w3-margin-bottom'>{errors.wbToDate}</div>}
                        </Grid>
                    </>
                )}
                <Grid item>
                    <Button
                        size='small'
                        color='primary'
                        variant="contained"
                        style={{ padding: 7, cursor: disableSubmitBtn ? 'not-allowed' : 'pointer' }}
                        disabled={disableSubmitBtn}
                        onClick={() => handleGetReportData(form.reportName, form.reportCategory, wbFromDate, wbToDate)}
                    >
                        Generate Report
                    </Button>
                </Grid>
                <Grid item>
                    <Tooltip title="Download" id='download-icon'>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ cursor: disableDownloadBtn ? 'not-allowed' : 'pointer', height: '35px' }}
                            onClick={() => window.print()}
                            disabled={disableDownloadBtn}
                        >
                            <DownloadIcon />
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            {renderWaterBalanceGraph()}
        </div>
    );
}