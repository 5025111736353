import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { getAllFacilities } from '../../Redux/actions';
import { DeleteIcon } from '../Common/TableUI';
import Table from '@mui/material/Table';
import { Box, Tooltip, TableCell, TableRow, IconButton, CircularProgress } from '@mui/material';
import { ChevronRight, Edit } from '@mui/icons-material';
import ConfirmDialog from '../Common/ConfirmDialog';
import { deleteFacility } from '../../Redux/actions';
import SearchBox from '../Common/SearchBox';
import FilterListIcon from '@mui/icons-material/FilterList';
import { removeNonValueFilter } from '../../utils';
import { setStoreData } from '../../Redux/actions';
import { Can } from '../../Context/Can';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Filter from '../Common/Filter';
import { green } from '@mui/material/colors';

import VisibilityIcon from '@mui/icons-material/Visibility';

export default function Facilities() {
    let navigate = useNavigate()
    const location = useLocation();
    const goToRoutes = (routeApi) => {
        navigate(routeApi, { replace: true });
    }

    const state = useSelector(st => st)
    const { role, getClientById: clientData  } = state;
    const userRole = role?.data?.data

    let { client_id: client_id } = useParams()
    const dispatch = useDispatch();
    const [responseData, setResponseData] = useState([])
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(Object.assign({}));
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [segementData, setSegmentData] = useState([]);
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])

    const [showLoader, setShowLoader] = useState(false)

    const [userAccess, setUserAccess] = useState([])

    const tableColumns = ['Facility Name', 'Segment', 'Location', 'Status', '']
    let tableContent = null;
    const [hoveredRowId, setHoveredRowId] = useState(null);


    const fetchData = () => {
        const params = {
            ...existingParams,
            title: existingParams?.search,
        }
        setShowLoader(true)
        dispatch(getAllFacilities(client_id, params)).then((res) => {
            if (res && res.status === 200) {
                setResponseData(res.data.data)
                const segments = res.data.allData.map((item) => (item.segment))
                const uniqueSegments = [...new Set(segments)];
                setSegmentData(uniqueSegments)
                setShowLoader(false)
            }
        });
    };

    useEffect(() => {
        fetchData();
    }, [queryParams]);

    useEffect(() => {
        if (userRole?.length > 0) {
            let arr = []
            for (const item of userRole) {
                if (item.role === "admin") {
                    item.adminAccess = {
                        edit: true,
                        delete: true,
                        id: '',
                        role: item.role
                    };
                } else if (item.permission && Array.isArray(item.permission) && item.permission.length > 0) {
                    item.adminAccess = {};
                    for (const permission of item.permission) {
                        if (permission.permissions.edit) {
                            item.adminAccess.edit = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''

                        }
                        if (permission.permissions.delete) {
                            item.adminAccess.delete = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''

                        }
                    }
                }
                arr.push(item)
            }
            if (arr?.length > 0) {
                setUserAccess(arr)
            }
        }
    }, [userRole])

    const triggerDelete = (item) => {
        setSelectedItem(item);
        setShowConfirmDialog(true);
    }

    const handleDeleteFacility = () => {
        if (selectedItem) {
            setShowConfirmDialog(false);
            dispatch(deleteFacility(client_id, selectedItem.id)).then((resp) => {
                updateResponse(resp, resp?.data?.message);
                setSelectedItem(null);
                fetchData();
            })
        }
    }

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        dispatch(setStoreData('alertMessage', {
            msg, type,
            handleClose: () => handleCloseDialog(),
        }))

    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
    }

    const updateQueryParams = (params) => {
        const nParams = removeNonValueFilter(Object.assign({}, existingParams, params));
        setQueryParams(nParams);
    }

    const handleSearch = (value) => {
        updateQueryParams({ search: value });
    }

    let facilityList = []

    if (showLoader) {
        facilityList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <CircularProgress />
                </TableCell>
            </TableRow>
        )
    } else if (responseData.length <= 0) {
        facilityList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <h5 className="w3-text-grey">No Data Found</h5>
                </TableCell>
            </TableRow>
        );
    } else {
        facilityList = responseData.map((item) => {
            let canEdit = false;
            let canDelete = false;
            let canView = false;
            let clPermissions = null;
            let clientAdmin = false;

            let admin = false;
            const clientPermission = clientData?.data?.data?.permission;
            if (clientPermission) {
                clPermissions = Object.keys(clientPermission).filter(key => clientPermission[key]).map(key => key.charAt(0).toUpperCase() + key.slice(1));
                
              }
            if (userAccess?.length > 0) {
                userAccess?.find(uItem => {
                    uItem.permission?.find(pItem => {
                        if ((pItem.itemId === item.id || item?.location?.id === pItem.itemId) && clPermissions?.includes('Facility')) {
                            if (pItem.permissions.edit) {
                                canEdit = true
                            }
                            if (pItem.permissions.view) {
                                canView = true
                            }
                            if (pItem.permissions.delete) {
                                canDelete = true
                            }
                        }
                        if(pItem.itemId === client_id && pItem.type === 'client' && pItem.permissions.edit && !clientAdmin) {
                            canEdit = true;
                            canDelete = true;
                            clientAdmin = true;
                        }
                        if(pItem.itemId === client_id && pItem.type === 'client' && pItem.permissions.view) {
                            canView = true;
                        }
                    })
                    if (uItem.role === 'admin') {
                        canEdit = true;
                        canDelete = true;
                        admin = true;
                    }
                })
            }
            return (
                <TableRow
                    onMouseEnter={() => {
                        setHoveredRowId(item?.id);
                    }}
                    onMouseLeave={() => {
                        setHoveredRowId(null);
                    }}
                >
                    <TableCell>{item?.facility_name}</TableCell>
                    <TableCell>{item?.segment}</TableCell>
                    <TableCell>{item?.location.name}</TableCell>
                    <TableCell>{item?.status}</TableCell>

                    <TableCell>
                        <Box component="div" display="flex" justifyContent={"right"} >
                            {canEdit &&  <Tooltip title="Edit">
                                <IconButton aria-label="edit"
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                    onClick={() => { 
                                        const location_id = queryParams.get('location_id');
                                        const searchValue = queryParams.get('search');
                                        const segmentValue = queryParams.get('segment');

                                        const state = {
                                            status: location_id || searchValue || segmentValue,
                                        };
                                        navigate(`${location.pathname}/edit/${item.id}`, { state: state }) }}
                                >
                                    <Edit className="cursor-pointer"
                                        style={{ color: 'black' }}
                                    />
                                </IconButton>
                            </Tooltip>}

                            <Can do='manage' on="DeleteFacility-xxx">
                                {canDelete && <Tooltip title="Delete">
                                    <IconButton aria-label="delete"
                                        style={{ opacity: hoveredRowId === item?.id ? 1 : 0 }}
                                        onClick={() => triggerDelete(item)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>}
                            </Can>

                            {!clientAdmin && canView && !admin && <Tooltip title="View">
                                <IconButton aria-label="view"
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px', marginRight: '10px' }}
                                    onClick={() => { 
                                        const location_id = queryParams.get('location_id');
                                        const searchValue = queryParams.get('search');
                                        const segmentValue = queryParams.get('segment');

                                        const state = {
                                            status: location_id || searchValue || segmentValue,
                                        };
                                        navigate(`${location.pathname}/edit/${item.id}`, { state: state }) }}
                                >
                                    <VisibilityIcon className="cursor-pointer" />
                                </IconButton>
                            </Tooltip>
                            }
                            <Tooltip title="Digitalisation View">
                                <IconButton aria-label="edit" >
                                    <ChevronRight className="cursor-pointer"
                                        style={{ color: green[500] }}
                                        onClick={() => {
                                            navigate(`/client/${client_id}/digitalisation/${item?.id}/live`)
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </TableCell>
                </TableRow>
            )
        });
    }

    tableContent = facilityList

    return (
        <Grid container>
            <Grid item md={12}>
            <Grid item container spacing={8} paddingY={2}>
                <Grid item  md={6}>
                    <Can do="manage" on="CreateFacility-xxx">
                        <Button
                            variant="contained"
                            
                            onClick={() => { goToRoutes(`${location.pathname}/create`) }}
                        >Create New Facility</Button>
                    </Can>
                </Grid>
                <Grid item  md={6} container justifyContent="flex-end" direction="row">
                    <Grid item container md={12} direction="row">
                        <Grid item md={11} container justifyContent="flex-end">
                            <h6 style={{ paddingRight: '5px', fontSize: '15px' }}>Search By: </h6>
                            <SearchBox
                                value={queryParams.get('search') || ''}
                                placeholder='Facility Name'
                                search={handleSearch}
                            />
                        </Grid>
                        <Grid item container md={1} justifyContent="flex-end">
                            <IconButton onClick={() => setShowFilterDialog(true)} title="Apply Filter">
                                <FilterListIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Filter
            filterArea = "Facilities"
            segmentDataOption = {segementData}
            showFilterDialog= {showFilterDialog}
            setShowFilterDialog = {setShowFilterDialog}
            updateQueryParams={updateQueryParams}
            queryParams={queryParams}
            />

            <Grid paddingX={0} paddingY={1}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" style={{ tableLayout: 'auto' }}>
                        <TableHead>
                            <TableRow>
                            {tableColumns.map(tCol => (
                                <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>{tCol}</TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableContent}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {showConfirmDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setSelectedItem({})
                            setShowConfirmDialog(false)
                        }}
                        handleOk={handleDeleteFacility}
                        message={'Are you sure to delete this ?'}
                    />
                ) : null}
            </Grid>
        </Grid>
    )
}




