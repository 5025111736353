import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getClientLocations, deleteClientLocation } from '../../Redux/actions';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SearchBox from '../Common/SearchBox';

import { CircularProgress, Tooltip, IconButton, Box } from '@mui/material';
import { Edit } from '@mui/icons-material';
import ConfirmDialog from '../Common/ConfirmDialog';
import Paper from '@mui/material/Paper';
import { setStoreData } from '../../Redux/actions';
import { Can } from '../../Context/Can';
import { DeleteIcon } from '../Common/TableUI';

import VisibilityIcon from '@mui/icons-material/Visibility';


export default function ManageLocation() {
    let { client_id } = useParams()
    const dispatch = useDispatch()
    const location = useLocation();

    const state = useSelector(st => st)
    const { role } = state;
    const userRole = role?.data?.data;
    const [clientLocationsData, setClientLocationsData] = useState();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [userAccess, setUserAccess] = useState([])

    const [showLoader, setShowLoader] = useState(false)

    const [queryParams, setQueryParams] = useSearchParams();
    const [hoveredRowId, setHoveredRowId] = useState(null);

    const fetchData = () => {
        const params = {
            title: queryParams.get("search")
        }
        setShowLoader(true)
        dispatch(getClientLocations(client_id, params)).then((res) => {
            if (res && res.status === 200) {
                const respData = res.data
                if (!respData) {
                    return;
                }
                setClientLocationsData(respData.data)
                setShowLoader(false)
            }

        })
    }

    useEffect(() => {
        fetchData();
    }, [client_id, queryParams])

    useEffect(() => {
        if (userRole?.length > 0) {
            let arr = []
            for (const item of userRole) {
                if (item.role === "admin") {
                    item.adminAccess = {
                        edit: true,
                        delete: true,
                        id: '',
                        role: item.role
                    };
                } else if (item.permission && Array.isArray(item.permission) && item.permission.length > 0) {
                    item.adminAccess = {};
                    for (const permission of item.permission) {
                        if (permission.permissions.edit) {
                            item.adminAccess.edit = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''
                        }
                        if (permission.permissions.delete) {
                            item.adminAccess.delete = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''
                        }
                        if (permission.permissions.view) {
                            item.adminAccess.view = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''
                        }
                    }
                }
                arr.push(item)
            }
            if (arr?.length > 0) {
                setUserAccess(arr)
            }
        }
    }, [userRole])
    let navigate = useNavigate()
    const goToRoutes = (routeApi) => {
        navigate(routeApi, { replace: true });
    }

    const triggerDelete = (client) => {
        setShowConfirmDialog(true);
        setSelectedItem(client);
    }

    const deleteLocation = () => {
        dispatch(deleteClientLocation(client_id, selectedItem)).then((resp) => {
            updateResponse(resp, resp?.data?.message);
            setShowConfirmDialog(false)
            setSelectedItem(null);
            fetchData();
        })
    }

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res.message;
        let type = "fail";
        if (res && res.success) {
            msg = message;
            type = "success";
        }
        dispatch(setStoreData('alertMessage', {
            msg, type,
            handleClose: () => handleCloseDialog(),
        }))

    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null))
    }

    const updateQueryParams = (params) => {
        const nParams = Object.assign({}, queryParams, params);
        setQueryParams(nParams);
    }

    const handleSearch = (value) => {
        updateQueryParams({ search: value });
    }
    let locationList = [];
    if (showLoader) {
        locationList = (<TableRow>
                <TableCell colSpan={6} align="center">
                    <CircularProgress />
                </TableCell>
            </TableRow>)
    } else if (clientLocationsData?.length <= 0) {
        locationList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <h5 className='w3-text-grey'>
                        No Data Found
                    </h5>
                </TableCell>
            </TableRow>
        )
    } else {
        locationList = (clientLocationsData?.length > 0 && clientLocationsData.map((item) => {
            let canEdit = false;
            let canDelete = false;
            let canView = false;
            let clientAdmin = false;
            let admin = false;
            if (userAccess?.length > 0) {
                userAccess?.find(uItem => {
                    uItem.permission?.find(pItem => {
                        if (pItem.itemId === item.id) {
                            if (pItem.permissions.edit) {
                                canEdit = true
                            }
                            if (pItem.permissions.view) {
                                canView = true
                            }

                            if (pItem.permissions.delete) {
                                canDelete = true
                            }
                        }
                        if(pItem.itemId === client_id && pItem.type === 'client' && pItem.permissions.edit && !clientAdmin) {
                            canEdit = true;
                            canDelete = true;
                            clientAdmin = true;
                        }
                        if(pItem.itemId === client_id && pItem.type === 'client' && pItem.permissions.view) {
                            canView = true
                        }
                    })
                    if (uItem.role === 'admin') {
                        canEdit = true;
                        canDelete = true;
                        admin = true;
                    }
                })
            }
            return <TableRow key={item?.id}
                onMouseEnter={() => {
                    setHoveredRowId(item?.id);
                }}
                onMouseLeave={() => {
                    setHoveredRowId(null);
                }}
                >
                <TableCell>{item?.name}</TableCell>
                <TableCell style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{item?.map_url || '-'}</TableCell>
                <TableCell>
                    <Box component="div" display="flex" justifyContent="right" >
                    {/* <Can do="manage" on="EditLocation"> */}
                    {canEdit && <Tooltip title="Edit">
                        <IconButton aria-label="edit"
                            style={{ opacity: hoveredRowId === item?.id ? 1 : 0 ,paddingLeft:'0px'}}
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(`${location.pathname}/edit/${item?.id}`, { state: { status: queryParams.get('search') } })
                            }}>
                            <Edit className="cursor-pointer"
                                style={{ color: 'black' }}
                            />
                        </IconButton>
                    </Tooltip>}
                    {/* </Can> */}
                    <Can do='manage' on="DeleteLocation-xxx">
                        {canDelete && <Tooltip title="Delete">
                            <IconButton aria-label="delete" 
                                style={{ opacity: hoveredRowId === item?.id ? 1 : 0 }}
                                onClick={(e) => {
                                e.stopPropagation();
                                triggerDelete(item?.id)
                            }}
                            >
                                <DeleteIcon/> 

                            </IconButton>
                        </Tooltip>}
                    </Can>
                    {!clientAdmin && canView && !admin && <Tooltip title="View">
                        <IconButton aria-label="view"
                            style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px', marginRight: '10px' }}
                            onClick={() =>  navigate(`${location.pathname}/edit/${item?.id}`, { state: { status: queryParams.get('search') } })}
                        >
                            <VisibilityIcon className="cursor-pointer" />
                        </IconButton>
                    </Tooltip>
                    }
                    </Box>
                </TableCell>
            </TableRow>
        }))
    }
    return (
        <Grid container>
            <Grid item md={12}>
            <Grid item container spacing={8} paddingY={2}>
                <Grid item  md={6}>
                    <Can do="manage" on="CreateLocation-xxx">
                        <Button
                            variant="contained"
                            
                            onClick={() => { goToRoutes(`${location.pathname}/create`) }}
                        >Create New Location</Button>
                    </Can>
                </Grid>
                <Grid item md={6} container justifyContent="flex-end" direction="row">
                    <h6 style={{ paddingRight: '5px', fontSize: '15px' }}>Search By: </h6>
                    <SearchBox
                        value={queryParams.get('search') || ''}
                        placeholder='Location Name'
                        search={handleSearch}
                    />
                </Grid>
            </Grid>
                <Grid paddingX={0} paddingY={1}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" style={{ tableLayout:  'auto' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>Location Name</TableCell>
                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}>Map URL</TableCell>
                                    <TableCell style={{ fontSize: '14px', fontWeight: 'bold' }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {locationList}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            {showConfirmDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setSelectedItem({})
                            setShowConfirmDialog(false)
                        }}
                        handleOk={deleteLocation}
                        message={'Are you sure to delete this location?'}
                    />
                ) : null}
            </Grid>
        </Grid>
    )
}




