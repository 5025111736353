import React, { useCallback, useState, useEffect } from 'react';
import { InputBase } from '@mui/material';
import { withStyles, createStyles } from '@mui/styles';
import debounce from 'lodash/debounce';

const BootstrapInput = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {

            },
        },
        input: {
            // borderRadius: 4,
            // position: 'relative',
            // backgroundColor: 'white',
            // border: '1px solid #ced4da',
            // width: '200px',
            // height: 20,
            // padding: '4px 10px',
            // marginBottom:'5px',
            // fontFamily:'Space Grotesk'
        },
    }),
)(InputBase);


const SearchBox = (props) => {

    const { search, placeholder, value } = props;
    const [searchValue, setSearchValue] = useState(value);
    const handler = useCallback(debounce(search, 500), [search]);

    useEffect(() => {
        if( value === ''){
            setSearchValue(value);
        }
    }, [value]); 

    const handleKeyDown = (event) => {
        const val = event.target.value;
        setSearchValue(val);
        if (val.length >= 0) {
            handler(val);
        }
    };

    const inputProps = {
        placeholder,
        onChange: handleKeyDown,
        value: searchValue,
        type: "search"
    }

    return (
    <div className='search-box'>
            <BootstrapInput
                {...inputProps}
            />
        </div>
    )
};

export default SearchBox;