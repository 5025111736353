/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react';
import { Box, TextField, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import AlertDialog from '../Common/AlertDialog';
import ConfirmDialog from '../Common/ConfirmDialog';

import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { createInvite, getUserInviteById, updateInvite, getClientLocations, updateJoinedUser, getJoinedUserById, getProjectById, getFacilityById, getClientLocationById } from '../../Redux/actions'


const makeRow = (key, type, name, level, parentId, itemId, permission, childFound = false) => {
    return {
        key,
        parentId,
        type,
        level,
        name,
        itemId,
        parents: [],
        isMasterChecked: key == 'location',
        isAddChecked: permission.add || false,
        isAddDisabled: false,
        isEditChecked: permission.edit || false,
        isEditDisabled: permission.view ? true : false,
        isDeleteChecked: permission.delete || false,
        isDeleteDisabled: false,
        isViewChecked: permission.view || false,
        isViewDisabled: permission.edit ? true : false,
        childFound
    }
}

const PermissionRow = ({ item, handleRowChange, handleMasterChange, clientAdminChecked, clientViewChecked }) => {

    let hasTrueValue = false
    const propertiesToCheck = ["isAddChecked", "isEditChecked", "isDeleteChecked", "isViewChecked"];
    for (const key of propertiesToCheck) {
        if (item.hasOwnProperty(key) && item[key] === true) {
            hasTrueValue = true;
        }
    }
    let clientAdmin = false;

    const isClientTypeAndEditChecked = item.type === 'client' && item.isEditChecked;
    if (isClientTypeAndEditChecked || clientAdminChecked) {
        clientAdmin = true;
    }

    let clientView = false;
    const isClientTypeAndViewChecked = item.type === 'client' && item.isViewChecked;
    if (isClientTypeAndViewChecked || clientViewChecked) {
        clientView = true;
    }


    const getTooltipTitle = (type) => {
        switch (type) {
            case 'location':
                return 'Location and all facilities under it';
            case 'facility':
                return 'Facility and all projects under it';
            case 'project':
                return 'Edit and delete project';
            case 'client':
                return 'Manage everything under the client';
            default:
                return '';
        }
    };
    return <TableRow id={item.key}>
        <TableCell className={`level${item.level}`} style={{ width: '75%' }}>
            <div style={{ display: 'flex' }}>
                {(item.level < 3 && item.key.indexOf('new') != 0) && <Button
                    variant="outlined"
                    size="small"
                    onClick={() => { handleMasterChange(item) }}
                    disabled={((clientAdmin || clientView) && item.type !== 'client') ? true : hasTrueValue}
                    className={`${!item.childFound ? "visibility-hidden" : ""} user-permission-add-btn`}
                    style={{ marginRight: '10px' }}> {item.isMasterChecked ? '-' : '+'}</Button>}
                <div>{item.name}</div>
            </div>
        </TableCell>

        <TableCell style={{ width: '25%' }}>
            <Grid container justifyContent="flex-end">
                {item.key.indexOf('new') != 0 && <FormControlLabel
                    // label={getTooltipTitle(item.type)}
                    control={
                        <Tooltip title={getTooltipTitle(item.type)}>
                            <Checkbox
                                checked={item.isEditChecked}
                                onChange={e => handleRowChange(item, 'edit')}
                                disabled={((clientAdmin || clientView) && item.type !== 'client') ? true : (item.isEditDisabled || item.isMasterChecked)}

                            />

                        </Tooltip>
                    }
                />}
            </Grid>
        </TableCell>
        <TableCell style={{ width: '25%', textAlign: 'right', position: ' relative', right: '-20px' }}>
            <Grid container justifyContent="flex-end">
                {item.key.indexOf('new') != 0 && <FormControlLabel
                    control={
                        <Tooltip title="View item">
                            <Checkbox
                                checked={item.isViewChecked}
                                onChange={e => handleRowChange(item, 'view')}
                                disabled={((clientAdmin || clientView) && item.type !== 'client') ? true : (item.isViewDisabled || item.isMasterChecked)}

                            />

                        </Tooltip>
                    }
                />}
            </Grid>
        </TableCell>
        {/* <TableCell style={{ width: '25%' }}>
            {item.key.indexOf('new') != 0 && item.type === 'project' && <FormControlLabel
                control={
                    <Tooltip title="Delete project">
                        <Checkbox
                            checked={item.isDeleteChecked}
                            onChange={e => handleRowChange(item, 'delete')}
                            disabled={item.isDeleteDisabled || item.isMasterChecked}
                        />
                    </Tooltip>
                }
            />}
        </TableCell> */}
    </TableRow>
}
/*

[]
*/

export default function User(props) {

    const location = useLocation();
    const locationState = location.state;

    const { client_id, id } = useParams();
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const state = useSelector(st => st);
    const { user, getClientById } = state;


    const formData = {
        name: '',
        emailId: ''
    }

    const [clientLocations, setClientLocations] = useState(null);
    const [form, setForm] = useState(formData);
    const [isLoading, setIsLoading] = useState(false);
    const error = {};
    const [errors, setErrors] = useState(error);
    const [checked, setChecked] = useState(null);

    const [clientData, setClientData] = useState(null);

    const [alertMessage, setAlertMessage] = useState();
    const [msgType, setMsgType] = useState("success");
    const [queryParams, setQueryParams] = useSearchParams();


    const [userPermissions, setUserPermissions] = useState(null);
    const [permissionRows, setPermissionRows] = useState([]);

    const [clPermissions, setClPermissions] = useState([]);
    const [permissionArr, setPermissionArr] = useState([]);
    const [isPermitted, setIsPermitted] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [permissionChecked, setPermissionChecked] = useState(false);
    const [clientAdminChecked, setClientAdminChecked] = useState(false);
    const [clientViewChecked, setClientViewChecked] = useState(false);
    const [statusButtonText, setStatusButtonText] = useState('');

    const [itemNotFound, setItemNotFound] = useState(false);

    const customerUsersQueryParams = localStorage.getItem("customerUsersQueryParams");

    const fetchData = () => {
        if (!client_id) {
            return
        }
        setIsLoading(true);
        if (getClientById?.data?.data) {
            setItemNotFound(false)
            setClientData(getClientById.data.data)
            const values = getClientById.data.data?.permission

            if (values) {
                const trueValues = Object.keys(values).filter(key => values[key]).map(key => key.charAt(0).toUpperCase() + key.slice(1));
                setClPermissions(trueValues);
                setIsPermitted(true);
            } else {
                setIsPermitted(false);
            }
        } else {
            setItemNotFound(true)
        }
        setIsLoading(false)
        const params = {
            title: queryParams.get("search"),
            scope: 'permission-tree'
        }
        dispatch(getClientLocations(client_id, params)).then((res) => {
            const response = res?.data?.data;
            if (!response) {
                return
            }
            setClientLocations(response)
        })
    }

    useEffect(() => {
        if (id && permissionRows?.length > 0 && !permissionChecked) {
            const hasTrueValue = permissionRows.some((obj) => {
                return Object.entries(obj).some(([key, value]) => key !== "childFound" && value === true);
            });
            if (!hasTrueValue) {
                const values = getClientById.data.data?.permission

                if (values) {
                    const trueValues = Object.keys(values).filter(key => values[key]).map(key => key.charAt(0).toUpperCase() + key.slice(1));
                    setClPermissions(trueValues);
                    setIsPermitted(true);
                }
            }
        }

        const isClientAdminChecked = permissionRows.some(
            row => row.type === 'client' && row.isEditChecked
        );
        const isClientViewChecked = permissionRows.some(
            row => row.type === 'client' && row.isViewChecked
        );
        if (isClientAdminChecked) {
            setClientAdminChecked(true)
        } else {
            setClientAdminChecked(false)
        }

        if (isClientViewChecked) {
            setClientViewChecked(true)
        } else {
            setClientViewChecked(false)
        }
    }, [permissionRows, id]);



    useEffect(() => {
        let clientPermissions = [];
        const permission = clientData?.permission;
        if (permission) {
            for (let key in permission) {
                if (permission?.hasOwnProperty(key) && permission[key] === true) {
                    const per = { name: key };
                    clientPermissions.push(per)
                }
            }
        }
        const order = ["location", "facility", "project", "meter"];

        clientPermissions?.sort((a, b) => {
            let indexA = order.indexOf(a.name);
            let indexB = order.indexOf(b.name);

            if (indexA === -1) return 1;
            if (indexB === -1) return -1;

            return indexA - indexB;
        });
        // setUserPermissions(clientPermissions);
    }, [clientData])

    useEffect(() => {
        fetchData()
    }, [client_id, getClientById?.data?.data]);
    useEffect(() => {
        const order = ["location", "facility", "project", "meter"];

        const reorderedArray = order.reduce((acc, item) => {
            const index = clPermissions?.findIndex(i => i.toLowerCase() === item);
            if (index !== -1) {
                acc.push(clPermissions[index]);
            }
            return acc;
        }, []);
        setPermissionArr(reorderedArray)

    }, [clPermissions])

    useEffect(() => {
        if (!clientLocations) {
            return;
        }

        if (permissionArr?.length == 0) {
            return;
        }

        const firstRowItem = permissionArr[0]
        // key, type, name, level, parentId, itemId, permission
        const defaultRow = makeRow(client_id, 'client', "Client", 1, '', client_id, getItemPermissions('client', client_id))
        const rows = clientLocations?.reduce((agg, item) => {
            let childFound = false;
            if (permissionArr.includes('Location')) {
                if (item.facility?.length > 0 && permissionArr.includes('Facility')) {
                    childFound = true;
                }
                agg.push(makeRow(`loc-${item.id}`,
                    'location',
                    item.name, 1, '',
                    item.id,
                    getItemPermissions('location', item.id), childFound));
            }
            // if (firstRowItem !== 'Facility' && permissionArr.includes('Facility')) {
            //     agg.push(makeRow(`new-fac-${item.id}`,
            //         'facility',
            //         'Facility', 2,
            //         `loc-${item.id}`,
            //         `${item.id}`,
            //         getItemPermissions('facility', `${item.id}`)));
            // }

            item.facility.forEach(fac => {
                let projectUnderFacility = false;
                if (fac.projects.length > 0 && permissionArr.includes('Project')) {
                    projectUnderFacility = true;
                }
                if (permissionArr.includes('Facility')) {
                    agg.push(makeRow(`fac-${fac.id}`,
                        'facility',
                        fac.facility_name,
                        2,
                        `loc-${item.id}`,
                        fac.id,
                        getItemPermissions('facility', fac.id), projectUnderFacility));

                }
                // if (firstRowItem !== 'Project' && permissionArr.includes('Project')) {
                //     agg.push(makeRow(`new-pro-${fac.id}`,
                //         'project',
                //         'Project', 3,
                //         `fac-${fac.id}`,
                //         `${fac.id}`,
                //         getItemPermissions('project', `${fac.id}`)));
                // }
                fac.projects.forEach(pro => {
                    if (permissionArr.includes('Project')) {
                        agg.push(makeRow(`pro-${pro.pid}`,
                            'project',
                            (pro.short_name || pro.projname), 4,
                            `fac-${fac.id}`,
                            pro.pid,
                            getItemPermissions('project', pro.pid)));
                    }
                });
            });
            // defaultRow.childFound = childFound;
            return agg;

        }, [defaultRow])
        setPermissionRows(rows);

    }, [clientLocations, permissionArr])

    const getItemPermissions = (type, itemId) => {
        const itemPermissions = form?.permission?.find(item => item.type === type && item.itemId === itemId);
        return itemPermissions ? itemPermissions.permissions : { add: false, edit: false, delete: false };
    };

    useEffect(() => {

        if (id) {
            if (locationState?.status === 'invited') {
                dispatch(getUserInviteById(id)).then((res) => {
                    const response = res.data.data;
                    if (!response) {
                        setItemNotFound(true);
                        return
                    }
                    const { name, emailId, permission, id, status } = response;
                    const flowData = {
                        id,
                        name,
                        emailId,
                        permission,
                        status
                    }
                    if (permission) {
                        setChecked(permission);
                        setUserPermissions(permission)
                    }
                    const initialButtonName = status === 'Joined' ? 'Deactivate User' : 'Activate User';
                    setStatusButtonText(initialButtonName);
                    setForm(flowData);


                })
            } else {
                dispatch(getJoinedUserById(client_id, id)).then((res) => {
                    const response = res.data.data;
                    if (!response) {
                        return
                    }
                    const { name, user_id, permission, id, status, authUser } = response;
                    const flowData = {
                        id,
                        name,
                        emailId: user_id,
                        permission,
                        status,
                        authStatus: authUser.status
                    }
                    if (permission) {
                        setChecked(permission);
                        setUserPermissions(permission)
                    }
                    const initialButtonName = status === 'Joined' ? 'Deactivate User' : 'Activate User';
                    setStatusButtonText(initialButtonName);
                    setForm(flowData);

                })
            }
        }

    }, [id])

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setChecked((prevState) => ({
            ...prevState,
            [name]: checked
        }));
        const err = Object.assign({}, errors);
        if (err['permission']) {
            err['permission'] = null;
        }
        setErrors(err);
    }

    let isDataLoading = null

    const handleChange = (e) => {
        const { name, value } = e.target;
        const data = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        if (name === 'emailId') {
            data[name] = value.toLowerCase();
        }
        setForm(data);
        if (disableButton) {
            setDisableButton(false)
        }
    }
    const checkedAllFalse = checked && Object.values(checked).every(value => value === false);
    const validateData = () => {
        const err = { ...errors };
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

        let hasErrors = false;
        if (!form.name || form.name.trim() === '') {
            hasErrors = true;
            err.name = 'This field is mandatory';
        }
        if (!form.emailId || form.emailId.trim() === '') {
            hasErrors = true;
            err.emailId = 'This field is mandatory';
        }

        if (form.emailId) {
            const emailValidation = emailRegex.test(form.emailId);
            if (!emailValidation) {
                hasErrors = true;
                err.emailId = 'Enter valid email id';
            }
        }

        if (checked?.length === 0 || (userPermissions?.length === 0 || !userPermissions)) {
            hasErrors = true;
            err.permission = 'Please select at least one checkbox to submit';
        }

        // if (!checked || checkedAllFalse) {
        //     hasErrors = true;
        //     err.permission = 'Required: Please check the permissions';
        // }
        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    };

    const showAlert = (msg, type) => {
        setAlertMessage(msg);
        setMsgType(type);
    }

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res?.message;
        if (msg) {
            let type = "fail";
            if (res && res.success) {
                msg = message;
                type = "success";
            }
            showAlert(msg, type);
        }
    };

    const handleSubmit = async () => {
        setDisableButton(true)
        const checkForm = validateData();
        if (checkForm) {
            const data = {
                name: form.name,
                emailId: form.emailId,
                permission: userPermissions,
                client_id: client_id,
                status: form.status,
                type: 'clientInvite'
            }   
            
            if (form.id) {
                const err = { ...errors };
                let hasActiveItem = false;
                const permissionActions = {
                    project: getProjectById,
                    facility: getFacilityById,
                    location: getClientLocationById,
                };
    
                for (const uPermissions of userPermissions) {
                    const action = permissionActions[uPermissions.type];
                    if (action) {
                        const res = await dispatch(action(client_id, uPermissions.itemId));
                        const response = uPermissions.type === 'project' ? res.data.projectData :
                                         uPermissions.type === 'facility' ? res?.data?.facilityData :
                                         res.data.data;
    
                        if (response) {
                            hasActiveItem = true
                        }
                    } else if(uPermissions?.name === "Client" && (uPermissions?.permissions?.view || uPermissions?.permissions?.edit)) {
                        hasActiveItem = true
                    }
                }
    
                if (!hasActiveItem) {
                    err.permission = 'Please select at least one checkbox to submit';
                    setErrors(err);
                    setDisableButton(false);
                    return;
                }
    
                if (locationState.status === 'invited') {
                    dispatch(updateInvite(form.id, data)).then(res => {
                        setDisableButton(false)
                        if (!res) {
                            return;
                        }
                        if (res.status === 200 || res.status === 201) {
                            updateResponse(res, 'User updated successfully');
                        }
                        if (!res.data.success) {
                            setAlertMessage(res.data.message);
                            setMsgType('fail');
                        }
                    })
                } else {
                    dispatch(updateJoinedUser(form.id, data)).then(res => {
                        setDisableButton(false)
                        if (!res) {
                            return;
                        }
                        if (res.status === 200 || res.status === 201) {
                            updateResponse(res, 'User updated successfully');
                        }
                        if (!res.data.success) {
                            setAlertMessage(res.data.message);
                            setMsgType('fail');
                        }

                    })
                }

            } else {
                dispatch(createInvite(data)).then((resp) => {
                    setDisableButton(false)
                    if (resp.status === 200 || resp.status === 201) {
                        updateResponse(resp, 'User invited successfully');
                    }
                    if (!resp.data.success) {
                        setAlertMessage(resp.data.message);
                        setMsgType('fail');
                    }

                })
            }

        }
    }
    const handleCloseDialog = () => {
        setAlertMessage(null);
        const tab = locationState?.status === 'invited' ? 'invited' : 'joined';
        customerUsersQueryParams
            ? navigate(`/client/${client_id}/users?${customerUsersQueryParams?.toString()}`)
            : navigate(`/client/${client_id}/users?tab=${tab}`);
    }

    const resetRowFlags = (row) => {
        row.isEditChecked = false;
        row.isViewChecked = false;
        row.isViewDisabled = false;
        row.isEditDisabled = false;
    }
    
    const isMainParentChecked = (projectParent) => {
        const parent = permissionRows.find(item => `fac-${item.itemId}` === projectParent);
        if (parent) {
            const mainParent = permissionRows.find(item => `loc-${item.itemId}` === parent.parentId);
            return mainParent && (mainParent.isEditChecked || mainParent.isViewChecked);
        }
        return false;
    }

    const handleRowChange = (item, action) => {
        const err = Object.assign({}, errors);
        if (err['permission']) {
            err['permission'] = null;
        }
        setErrors(err);
        const newPerms = permissionRows.reduce((changed, row) => {
            if (row.key === item.key) {
                if (action == 'add') {
                    row.isAddChecked = !row.isAddChecked;
                } else if (action == 'edit') {
                    row.isEditChecked = !row.isEditChecked;
                } else if (action == 'delete') {
                    row.isDeleteChecked = !row.isDeleteChecked;
                } else if (action == 'view') {
                    row.isViewChecked = !row.isViewChecked;
                }
            }

            if (row.isEditChecked || row.isViewChecked) {
                const clientChecked = permissionRows.find(item => item.type === 'client' && (item.isEditChecked || item.isViewChecked));
                switch (row.type) {
                    case 'location':
                        if(clientChecked) {
                            resetRowFlags(row);
                        }
                    case 'facility':
                        const parentLocation = permissionRows.find(item => item.type === 'location' && (item.isEditChecked || item.isViewChecked) && `loc-${item.itemId}` === row.parentId);
                        if (parentLocation || clientChecked) {
                            resetRowFlags(row);
                        }
                        break;
            
                    case 'project':
                        const projectParent = row.parentId;
                        const parentFacility = permissionRows.find(item => item.type === 'facility' && (item.isEditChecked || item.isViewChecked) && `fac-${item.itemId}` === projectParent);
                        if (parentFacility || isMainParentChecked(projectParent)|| clientChecked) {
                            resetRowFlags(row);
                        }
                        break;
                }
            }

            if (action === 'view' && row.key === item.key) {
                row.isEditDisabled = row.isDeleteDisabled = row.isViewChecked && (item.itemId || !item.itemId);
            }

            if (action === 'edit' && row.key === item.key) {
                row.isViewDisabled = row.isEditChecked;
            }
            // const parent = row.parents.find(row => row == item.key);
            // if (parent) {
            //     const pRow = changed.find(row => row.key == parent);
            //     if (action == 'add') {
            //         row.isAddDisabled = row.isAddChecked = pRow.isAddChecked;
            //     }
            //     if (action == 'edit') {
            //         row.isEditDisabled = row.isEditChecked = pRow.isEditChecked;
            //     }
            //     if (action == 'delete') {
            //         row.isDeleteDisabled = row.isDeleteChecked = pRow.isDeleteChecked;
            //     }
            // }
            changed.push(Object.assign({}, row));

            const hasLocationPermissions = (obj) => {
                return (obj.isAddChecked || obj.isEditChecked || obj.isDeleteChecked || obj.isViewChecked);
            }

            const permission = changed
                .filter(hasLocationPermissions)
                .map(({ name, isAddChecked, isEditChecked, isDeleteChecked, isViewChecked, itemId, type, parentId }) => ({
                    name: name,
                    itemId: itemId,
                    type: type,
                    permissions: {
                        add: isAddChecked,
                        edit: isEditChecked,
                        delete: isDeleteChecked,
                        view: isViewChecked
                    },
                    parentId: parentId,
                    client_id: client_id
                }));

            setUserPermissions(permission)
            return changed;
        }, [])
        setPermissionChecked(true);
        setPermissionRows(newPerms);
        if (disableButton) {
            setDisableButton(false)
        }
    }
    const handleMasterChange = (selected) => {
        const newState = !selected.isMasterChecked;
        const children = permissionRows.reduce((agg, item) => {
            if (agg.indexOf(item.parentId) >= 0) {
                agg.push(item.key);
            }
            return agg;
        }, [selected.key]);
        const newPerms = permissionRows.reduce((agg, item) => {
            if (item.key == selected.key) {
                item.isMasterChecked = newState;
                item.isAddChecked = false;
                item.isEditChecked = false;
                item.isDeleteChecked = false;
                item.isViewChecked = false;
            }

            if (newState == false && children.indexOf(item.key) >= 0) {
                item.isMasterChecked = false;
            }
            agg.push(Object.assign({}, item));
            return agg;
        }, [])
        setPermissionRows(newPerms);
        if (disableButton) {
            setDisableButton(false)
        }

    }

    const handleUserStatus = () => {
        const status = form?.status === 'Joined' ? 'Inactive' : 'Joined';
        const newButtonText = status === 'Joined' ? 'Deactivate User' : 'Activate User';
        Object.assign(form, { status });
        form.client_id = client_id
        form.type = 'clientInvite';
        dispatch(updateJoinedUser(form.id, form)).then((res) => {
            if (!res.data) {
                showAlert('Something went wrong!', 'fail')
                return
            }
            if (res.data.success) {
                setStatusButtonText(newButtonText);
            }

            updateResponse(res, `User ${form?.status === 'Joined' ? 'Activated' : 'Deactivated'}`);
        })
    }
    if (isLoading || (id && form?.permission?.length <= 0)) {
        isDataLoading = (<TableRow>
            <TableCell colSpan={6} align="center">
                <div className="w3-center">
                    <CircularProgress />
                </div>
            </TableCell>
        </TableRow>)
    }

    const tab = locationState?.status === 'invited' ? 'invited' : 'joined';

    if (id && itemNotFound) {
        return <Grid container className='w3-padding'>
            <Grid item md={12}>
                <h6>Edit User</h6>
            </Grid>
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>

    } else if (!id && itemNotFound) {
        return <Grid container className='w3-padding' >
            <Grid item md={12}>
                <h6>Invite User</h6>
            </Grid>
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    Create users not available due to invalid client id.
                </h5>
            </Grid>
        </Grid>
    }

    return (
        <Grid container >
            <Grid container md={12} className='w3-padding'>
                <Grid item md={12}>
                    <h6>{form?.id ? 'Edit User' : 'Invite User'}</h6>
                </Grid>
                {isDataLoading ? isDataLoading : <>
                    {isPermitted && <><Grid container md={12} spacing={2} style={{ alignContent: 'flex-start' }}>
                        <Grid item md={4} style={{ display: 'flex', flexDirection: 'row', }}>
                            <TextField
                                fullWidth
                                label="Name"
                                name="name"
                                required
                                variant='outlined'
                                style={{ background: 'white' }}
                                className='w3-margin-bottom'
                                value={form.name}
                                onChange={handleChange} />

                        </Grid>
                        <Grid item md={4} style={{ display: 'flex', flexDirection: 'row', }}>
                            <TextField
                                fullWidth
                                label="Email"
                                name="emailId"
                                variant='outlined'
                                required
                                style={{ background: 'white' }}
                                className='w3-margin-bottom'
                                disabled={form.id}
                                value={form.emailId}
                                onChange={handleChange} />

                        </Grid>
                    </Grid>
                        <Grid container>
                            <Grid item md={4}>
                                {errors.name && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.name}</div>}
                            </Grid>
                            <Grid item md={4}>
                                {errors.emailId && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.emailId}</div>}
                            </Grid>
                        </Grid>
                        <Grid item md={8} sm={10} style={{ paddingRight: '15px' }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Asset</TableCell>
                                            <TableCell sx={{ textAlign: 'right' }}  >Manage</TableCell>
                                            <TableCell sx={{ textAlign: 'right' }}  >View</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {permissionRows.reduce((agg, item) => {
                                            const parent = permissionRows.find(row => row.key == item.parentId);
                                            if (parent && !parent.isMasterChecked) {
                                                return agg;
                                            }
                                            agg.push(<PermissionRow item={item} handleRowChange={handleRowChange}
                                                handleMasterChange={handleMasterChange} clientAdminChecked={clientAdminChecked} clientViewChecked={clientViewChecked} />)
                                            return agg;
                                        }, [])}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {errors.permission && <div className='error-text' style={{ marginTop: 4 }}>{errors.permission}</div>}

                            <Grid container style={{ margin: '30px 0' }}>
                                <Grid item md={6}>
                                    {form?.authStatus !== 'Inactive' && (user?.data?.user?.uniqueId !== form.emailId && form.status && form.status !== "Invited") && (form.status === 'Joined' || form.status === 'Inactive') && (<Button variant="outlined"
                                        className='w3-margin-right w3-deep-purple'
                                        onClick={handleUserStatus}
                                    >{statusButtonText}</Button>)}
                                </Grid>

                                <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Grid item>
                                        <Button variant="outlined"
                                            className='w3-margin-right'
                                            onClick={() => customerUsersQueryParams ? navigate(`/client/${client_id}/users?${customerUsersQueryParams?.toString()}`) : navigate(`/client/${client_id}/users?tab=${tab}`)}
                                        >Cancel</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained"
                                            disabled={form?.authStatus === 'Inactive' || disableButton}
                                            className=''
                                            onClick={handleSubmit}
                                        >{form?.id ? "Submit" : "Invite"}</Button>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid></>}
                </>}

            </Grid>

            {alertMessage ?
                (
                    <AlertDialog
                        handleClose={handleCloseDialog}
                        message={alertMessage}
                        type={msgType}
                        handleDialogClose={() => setAlertMessage(null)}
                    />
                )
                : null
            }
        </Grid>
    )
}