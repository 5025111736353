import { Paper, TextField, IconButton, Tooltip, FormControl, InputLabel, ListItemText, CircularProgress, Box, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createNewProject, deleteProject, setStoreData } from '../../Redux/actions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getProjectById, updateProject, getClientLocations, getAllFacilities, getCurrentUserRole, getClientById, deleteProjectImage } from '../../Redux/actions';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import ConfirmDialog from '../Common/ConfirmDialog'
import { DeleteIcon } from '../Common/TableUI';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import {isValidDate} from '../../utils/Common';
import moment from 'moment';
import ProjectSensors from './ProjectSensors';
import { meterGroupDisplayOrder } from '../../utils';

export default function ManageProjects() {

    const location = useLocation();
    const locationState = location?.state;

    const projectQueryParams = localStorage.getItem("projectQueryParams");
    
    let navigate = useNavigate()
    const goToRoutes = (routeApi) => {
        navigate(routeApi, { replace: true });
    }

    let initialForm = {
        projectName: "",
        projectShortName: "",
        map_url: "",
        plantType: "",
        projectType: "",
        capacity: "",
        opexCost: "",
        //startDt: "",
        dailyReportsEmail: "",
        stpType: "",
        facility: "",
        location: ""

    }

    const plantTypeValues = ['TMS', 'WTP', 'RO', 'STP', 'CT', 'ETP', 'TTP', 'QMS', 'JJM-IOT', 'Water Balance', 'Tank Level', 'Individual House', 'Others'];
    const projectTypeValues = ['Live', 'Demo/ Test', 'Water Balance', 'Not Applicable'];
    const stpTypeValues = ['SBR', 'EA', 'MBR', 'MBBR', 'ASP'];

    const state = useSelector(st => st)
    const { getClientById: clientData } = state
    // const { role: { data: { data: userRole } } } = state
    const [form, setForm] = useState(initialForm);
    const [showStpType, setShowStpType] = useState(false);
    const [startDate, setStartDate] = useState(null)
    const [uploadedImage, setUploadedImage] = useState();
    const [showConfirmUploadDialog, setShowConfirmUploadDialog] = useState(false);

    const dispatch = useDispatch();
    let { id: id, client_id: client_id } = useParams()
    const error = {};
    const [errors, setErrors] = useState(error);
    const [locationData, setLocationData] = useState(null);
    const [facilities, setFacilities] = useState(null)
    const [facilityName, setFacilityName] = useState('')
    const [projectAction, setProjectAction] = useState("Create New Project")
    const [selectedImage, setSelectedImage] = useState(null);
    const [deleteImage, setDeleteImage] = useState(null);
    const [disableButton, setDisableButton] = useState(false);
    const [projectFacility, setProjectFacility] = useState([])
    const [userRole, setUserRole] = useState([])
    const [selectedItem, setSelectedItem] = useState(null);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [userAccess, setUserAccess] = useState([]);
    const [itemNotFound, setItemNotFound] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [sensorOrderedData, setSensorOrderedData] = useState({});
    const [sensorOriginalData, setSensorOriginalData] = useState({});
    const [reportsChecked, setReportsChecked] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    useEffect(() => {
        setShowLoader(true)
        dispatch(getClientById(client_id)).then((res) => {
            if(!res?.data?.data) {
                setItemNotFound(true)
            }
            
        });
        dispatch(getClientLocations(client_id)).then((res) => {
            if (res && res.status === 200) {
                setLocationData(res.data.data)
            }
        });

        dispatch(getAllFacilities(client_id)).then((res) => {
            if (res && res.status === 200) {
                setFacilities(res.data.data)
            }
        });
        dispatch(getCurrentUserRole()).then((userResp) => {
            setUserRole(userResp?.data?.data)
        })
        setShowLoader(false)
    }, [])

    useEffect(() => {

        if (id) {
            setShowLoader(true);
            setProjectAction("Edit Project")
            dispatch(getProjectById(client_id, id)).then((resp) => {
                if(!resp.data.success) {
                    setItemNotFound(true)
                }

                const response = resp?.data?.projectData
                if (!response) {
                    setShowLoader(false);
                    return;
                }
                const { projname, short_name, mapurl, planttype, projecttype, plantcapacity, start_date, daily_reports_email, stp_type, facilities, location, image_path, opex_cost, sendReport, reportStartTime, reportEndTime } = response
                const meterData = response?.projectMeter;
                const groupedMeters = meterGroupDisplayOrder?.reduce((acc, group) => {
                    acc[group] = meterData?.filter(meterItem => meterItem?.meter?.meter_group === group);
                    return acc;
                }, {});
                setSensorOriginalData(groupedMeters);
                setSensorOrderedData(groupedMeters);

                const facilityId = facilities?.id
                const locationName = location?.id
                if (start_date) {
                    const originalDate = moment(start_date);
                    const isoString = originalDate.toISOString();
                    setStartDate(isoString)
                }
                if(sendReport) {
                    setReportsChecked(true)
                }
                if(reportStartTime) {
                    let currentDate = new Date();
                    let [hours, minutes, seconds] = reportStartTime.split(':').map(Number);
                    currentDate.setHours(hours, minutes, seconds, 0);
                    setStartTime(currentDate)
                }
                if(reportEndTime) {
                    let currentDate = new Date();
                    let [hours, minutes, seconds] = reportEndTime.split(':').map(Number);
                    currentDate.setHours(hours, minutes, seconds, 0);
                    setEndTime(currentDate)
                }
                setSelectedImage(image_path);

                const existData = {
                    projectName: projname,
                    projectShortName: short_name,
                    map_url: mapurl,
                    plantType: planttype,
                    projectType: projecttype,
                    capacity: plantcapacity,
                    dailyReportsEmail: daily_reports_email,
                    stpType: stp_type,
                    facility: facilityId,
                    location: locationName,
                    image_path,
                    opexCost: opex_cost
                }
                setForm(existData)
                setFacilityName(facilities?.facility_name);
                if (existData.plantType === 'STP') {
                    setShowStpType(true);
                }
            })
            setShowLoader(false);
        }

    }, [id]);

    useEffect(() => {
        const isGreenUser = userRole?.find(item => {
            if (item.role === "admin" && item.status !== 'Inactive') {
                return item
            }
        })
        const isClientAdminUser = userRole?.find(item => 
            item?.permission?.some(val => val?.type === 'client' && val?.permissions.edit && val.client_id === client_id)
            );
        
        for (const roles of userRole) {
            if (roles.permission) {
                let matchingFacility = [];
                for (const permission of roles.permission) {
                    if (!permission.role) {
                        const facility = facilities?.find(item => {
                            const isFacilityMatch = ((`fac-${item.id}` === permission.parentId) || (item.location.id === permission.itemId) || (permission.itemId === item.id))  && permission?.permissions?.edit;
                            const isProjectPermission = permission.name === "Project";
                            const isEditPermission = ((permission.itemId === Number(id) && permission.permissions.edit) || (item?.location?.id === permission.itemId && (permission.permissions.edit || permission.permissions.delete)) || item.id === permission.itemId || (permission.itemId === item.location_id && (permission.permissions.edit || permission.permissions.delete)))
                            if (((isFacilityMatch && isProjectPermission) || (isFacilityMatch && isEditPermission)) && !matchingFacility.some(mFac => mFac && mFac.id === item.id)) {
                                matchingFacility.push(item)

                            }
                        })
                    }
                }

                if (matchingFacility?.length > 0) {
                    setProjectFacility(matchingFacility)
                }
            }
        }
        if (isGreenUser || isClientAdminUser) {
            setProjectFacility(facilities)
        }
    }, [facilities, userRole])

    useEffect(() => {
        if (userRole?.length > 0) {
            let arr = []
            for (const item of userRole) {
                if (item.role === "admin") {
                    item.adminAccess = {
                        edit: true,
                        delete: true,
                        id: '',
                        role: item.role
                    };
                } else if (item.permission && Array.isArray(item.permission) && item.permission.length > 0) {
                    item.adminAccess = {};
                    for (const permission of item.permission) {
                        if (permission?.permissions?.edit) {
                            item.adminAccess.edit = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''

                        }
                        if (permission?.permissions?.delete) {
                            item.adminAccess.delete = true;
                            item.adminAccess.id = permission.itemId;
                            item.adminAccess.role = ''

                        }
                    }
                }
                arr.push(item)
            }
            if (arr?.length > 0) {
                setUserAccess(arr)
            }
        }
    }, [userRole])
    let canCreate = false;
    let canEdit = false;
    let canDelete = false;
    let canView = false;
    let clPermissions = null;
    let clientAdmin = false;
    let clientView = false;
    let clientLevelPermission = false;
    const clientPermission = clientData?.data?.data?.permission;
    if (clientPermission) {
        clPermissions = Object.keys(clientPermission).filter(key => clientPermission[key]).map(key => key.charAt(0).toUpperCase() + key.slice(1));

    }

    if (userAccess?.length > 0) {
        userAccess?.find(uItem => {
            uItem?.permission?.find(pItem => {
                if (pItem.type !== 'project' && !id) {
                    canCreate = true;
                }
                if ((pItem.itemId == id || form.location === pItem.itemId || form.facility === pItem.itemId) && clPermissions?.includes("Project")) {
                    if (pItem.permissions.edit) {
                        canEdit = true
                        canDelete = true
                    }
                    if (pItem.permissions.view) {
                        canView = true
                    }
                }
                if (pItem.client_id === client_id && pItem.type === 'client' && pItem.permissions.edit && !clientAdmin) {
                    canEdit = true
                    canDelete = true
                    clientAdmin = true;
                    clientLevelPermission = true;
                }
                if(pItem.itemId === client_id && pItem.type === 'client' && pItem.permissions.view && !clientView) {
                    canView = true
                    clientView = true
                    clientLevelPermission = true;
                }
            })
            if (uItem.role === 'admin') {
                canEdit = true
                canDelete = true
            }
            if(uItem.role === 'spoc') {
                canView = true
            }
        })        
    }

    const handleChange = (e, fieldName) => {
        const { value } = e.target;
        const data = (prevState) => ({
            ...prevState,
            [fieldName]: value
        })
        const err = Object.assign({}, errors);
        if (err[fieldName]) {
            err[fieldName] = null;
        }
        setErrors(err);
        setForm(data);
        if (disableButton) {
            setDisableButton(false)
        }

        if (fieldName === 'plantType') {
            setShowStpType(value === 'STP');
        }
    };

    const validateData = () => {
        const err = { ...errors };

        let hasErrors = false;

        if (!form.map_url) {
            hasErrors = true;
            err.map_url = 'This field is mandatory';
        } else {
            const coordinates = form.map_url;
            const [latitude, longitude] = coordinates.split(',');
            const lat = parseFloat(latitude);
            const lon = parseFloat(longitude);
            hasErrors = true;
            if (isNaN(lat) || isNaN(lon)) {
                err.map_url = 'Latitude and longitude must be valid numbers';
            } else if (lat < -90 || lat > 90) {
                err.map_url = 'Latitude out of range (-90 to 90 degrees)';
            } else if (lon < -180 || lon > 180) {
                err.map_url = 'Longitude out of range (-180 to 180 degrees)';
            } else {
                hasErrors = false;
            }
        }
        if (!form.projectName || form.projectName.trim() === '') {
            hasErrors = true;
            err.projectName = 'This field is mandatory';
        }
        const trimmedInput = form?.projectShortName?.trim();
        if (trimmedInput === '' && form.projectShortName !== '') {
            hasErrors = true;
            err.projectShortName = 'Invalid input';
        } 
        if (!form.projectShortName || form.projectShortName.trim() === '') {
            hasErrors = true;
            err.projectShortName = 'This field is mandatory';
        }
        if (form?.projectShortName?.length > 32) {
            hasErrors = true;
            err.projectShortName = 'Maximum 32 characters allowed';
        }

        if (!form.plantType) {
            hasErrors = true;
            err.plantType = 'This field is mandatory';
        }

        if (form.plantType === 'STP') {
            if (!form.stpType) {
                hasErrors = true;
                err.stpType = 'This field is mandatory';
            }
        }

        if (!form.projectType) {
            hasErrors = true;
            err.projectType = 'This field is mandatory';
        }

        // if (!form.location) {
        //     hasErrors = true;
        //     err.location = 'This field is mandatory';
        // }

        if (!form.facility) {
            hasErrors = true;
            err.facility = 'This field is mandatory';
        }

        if (!form.capacity) {
            hasErrors = true;
            err.capacity = 'This field is mandatory';
        }

        if (!form.opexCost) {
            hasErrors = true;
            err.opexCost = 'This field is mandatory';
        } else if (form.opexCost <= 0) {
            hasErrors = true;
            err.opexCost = 'Please enter a value greater than 0';
        } else if (!/^\d+$/.test(form.opexCost)) {
            hasErrors = true;
            err.opexCost = 'Please enter a valid digit';
        }

        if (!form.dailyReportsEmail) {
            hasErrors = true;
            err.dailyReportsEmail = 'This field is mandatory';
        } else {
            const emails = form.dailyReportsEmail.split(',');
            const invalidEmails = emails.filter(email => !/^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email.trim()));
            if (invalidEmails.length > 0) {
                hasErrors = true;
                err.dailyReportsEmail = 'Enter valid email addresses separated by commas';
            }
        }

        if (!startDate) {
            hasErrors = true;
            err.startDate = 'This field is mandatory';
        } else  if (!isValidDate(startDate)) {
            hasErrors = true;
            err.startDate = 'Please enter a valid date in the format DD/MM/YYYY';
        }

        if (!selectedImage || (uploadedImage && uploadedImage?.type !== "image/svg+xml")) {
            hasErrors = true;
            err.uploadedImage = 'Please upload an SVG file'
        }

        if (reportsChecked) {
            if (!startTime) {
                hasErrors = true;
                err.startTime = 'This field is mandatory';
            }
            if (!endTime) {
                hasErrors = true;
                err.endTime = 'This field is mandatory';
            }

            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            const todayDate = `${year}-${month}-${day}`;

            const startTimeDate = new Date(startTime);
            const endTimeDate = new Date(endTime);

            const startTimeHours = startTimeDate.getHours();
            const startTimeMinutes = startTimeDate.getMinutes();
            const endTimeHours = endTimeDate.getHours();
            const endTimeMinutes = endTimeDate.getMinutes();
            if (isNaN(startTimeHours) || isNaN(startTimeMinutes)) {
                hasErrors = true;
                err.startTime = "Please enter a valid time HH:MM";
            }

            if (isNaN(endTimeHours) || isNaN(endTimeMinutes)) {
                hasErrors = true;
                err.endTime = "Please enter a valid time HH:MM";
            }
            
            if (Date.parse(startTime) > Date.parse(endTime)) {
                hasErrors = true;
                err.timeDifference = "End time should be higher than the start time";
            }
        }

        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    };

    const handleFileChange = (event) => {
        let uploadImage;
        if (event.target.files.length) {
            const err = { ...errors };
            if (err['uploadedImage']) {
                err['uploadedImage'] = null;
            }
            uploadImage = event.target.files[0];
            if (uploadImage.type !== "image/svg+xml") {
                err.uploadedImage = 'Please upload an SVG file'
            } else {
                setUploadedImage(uploadImage)
                setSelectedImage(URL.createObjectURL(uploadImage));
            }
            setErrors(err);
            event.target.value = ''
        }
        if (disableButton) {
            setDisableButton(false)
        }
        // setShowConfirmUploadDialog(true)

    }

    const handleConfirmUpload = () => {
        const formData = new FormData();
        if (uploadedImage) {
            formData.append('imageFile', uploadedImage);
            formData.append('type', 'image');
            formData.append('size', uploadedImage.size);
        }
        setShowConfirmUploadDialog(false)
    }

    const getUpdatedSensorData = () => {
        const changedData = {};

        for (const group in sensorOrderedData) {
            const initialGroup = sensorOrderedData[group];
            const originalGroup = sensorOriginalData[group];
            if (JSON.stringify(initialGroup) !== JSON.stringify(originalGroup)) {
                let nextPosition = 1;
                let updatedMeters = []
                initialGroup?.forEach(item => {
                    updatedMeters.push({
                        meter_id: item.meter_id,
                        project_id: item.project_id,
                        position: nextPosition
                    });
                    nextPosition++;
                });
                if (initialGroup.length > 0) {
                    changedData[group] = updatedMeters;
                }
            }
        }
        return changedData;
    };

    const handleSubmit = async() => {
        setDisableButton(true)
        const checkForm = validateData();
        if (checkForm) {
            const data = {
                projectName: form.projectName,
                projectShortName: form.projectShortName,
                map_url: form.map_url,
                plantType: form.plantType,
                projectType: form.projectType,
                capacity: form.capacity,
                startDate,
                dailyReportsEmail: form.dailyReportsEmail,
                stpType: form.stpType,
                client_id,
                location: form.location,
                facility: form.facility,
                opex_cost: Number(form.opexCost),
                sendReport: reportsChecked,
                reportStartTime: startTime || null,
                reportEndTime: endTime || null
            }
            if (id) {
                if (JSON.stringify(sensorOrderedData) !== JSON.stringify(sensorOriginalData)) {
                    const orderChangedData = getUpdatedSensorData();
                    data.sensorOrderedData = orderChangedData
                }
            }
            const formData = new FormData();
            if (uploadedImage) {
                formData.append('imageFile', uploadedImage);
            }
            formData.append('data', JSON.stringify(data))

            if(id && deleteImage) {
                await dispatch(deleteProjectImage(client_id, id, deleteImage))
            }

            if (id) {
                dispatch(updateProject(formData, id)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return;
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                });
            } else {
                dispatch(createNewProject(formData)).then((resp) => {
                    setDisableButton(false)
                    if (!resp) {
                        return;
                    } else {
                        updateResponse(resp, resp?.data?.message);
                    }
                });
            }
        }
    };

    const handleDeleteMedia = (imageId) => {
        setSelectedImage(null);
        if(id) {
            setDeleteImage(imageId);
        }
    }

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res.message;
        if (msg) {
            let type = "fail";
            if (res && res.success) {
                msg = message;
                type = "success";
            }
            dispatch(setStoreData('alertMessage', {
                msg, type,
                handleClose: () => handleCloseDialog(),
            }))
        }

    };

    const handleCloseDialog = () => {
        dispatch(setStoreData('alertMessage', null));
        (projectQueryParams && id) 
        ? navigate(`/client/${client_id}/projects?${projectQueryParams?.toString()}`) 
        : navigate(`/client/${client_id}/projects`);
    }

    const triggerDelete = () => {
        setSelectedItem(id);
        setShowConfirmDialog(true);
    }

    const handleDeleteProject = () => {
        if (selectedItem) {
            setShowConfirmDialog(false);
            dispatch(deleteProject(client_id, selectedItem)).then((resp) => {
                updateResponse(resp, resp?.data?.message);
                setSelectedItem(null);
            })
        }
    }

    if (id && itemNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px',  alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </Grid>
        </Grid>

    } else if (!id && itemNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px',  alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    Create project not available due to invalid client id.
                </h5>
            </Grid>
        </Grid>
    }

    if (showLoader) {
        return <Grid container>
            <Grid style={{display: 'flex', justifyContent: 'center', width: '100vw', height: '500px',  alignItems: 'center' }}>
                <CircularProgress />
            </Grid>
        </Grid>
    }



    if (!clientLevelPermission && id && !canEdit && !canView) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    You don't have view or manage permission
                </h5>
            </Grid>
        </Grid>
    }
    return (
        <Paper elevation={1} style={{ margin: '16px' }}>
            <Grid container className='w3-padding-small'>
                <Grid container >
                    <Grid item md={12} className='w3-padding'>
                        <h6>{projectAction}</h6>
                    </Grid>
                    <Grid container item md={12} style={{ display: 'flex', flexDirection: 'row', }}>
                        <Grid container item md={12} className='w3-padding' style={{ alignContent: 'flex-start' }}>
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    required
                                    fullWidth
                                    value={form.projectName}
                                    label="Project Name"
                                    name="projectName"
                                    onChange={(e) => handleChange(e, 'projectName')} />
                            </Grid>
                            {errors.projectName && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.projectName}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    fullWidth
                                    required
                                    value={form.projectShortName}
                                    label="Project Short Name"
                                    name="projectShortName"
                                    onChange={(e) => handleChange(e, 'projectShortName')} />
                            </Grid>
                            {errors.projectShortName && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.projectShortName}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    required
                                    fullWidth
                                    value={form.map_url}
                                    label="Map Coordinates"
                                    placeholder='12.9716, 77.5946'
                                    name="map_url"
                                    onChange={(e) => handleChange(e, 'map_url')} />

                            </Grid>
                            {errors.map_url && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.map_url}</div>}
                            {/* <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <Grid item md={3}><p>Location</p></Grid>
                                <Grid item md={9}>
                                    <Select
                                        fullWidth
                                        value={form?.location}
                                        name="location"
                                        onChange={(e) => handleChange(e, 'location')}
                                    >
                                        {locationData?.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
                                    </Select>
                                    {errors.location && <div className='error-text '>{errors.location}</div>}
                                </Grid>
                            </Grid> */}


                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="demo-multiple-name-label">Facility *</InputLabel>
                                    <Select
                                        fullWidth
                                        required
                                        value={form.facility}
                                        name="facility"
                                        label="Facility"
                                        onChange={(e) => handleChange(e, 'facility')}
                                        renderValue={(selected) => {
                                            const selectedItem = projectFacility?.find(item => {
                                                return item.id === selected
                                            });
                                            return (
                                                <div>
                                                    {selectedItem?.facility_name ? selectedItem.facility_name : facilityName}
                                                </div>
                                            );
                                        }}
                                    >
                                        {!id && projectFacility?.map(item => <MenuItem key={item.id} value={item.id}><ListItemText primary={item?.facility_name} secondary={item?.location?.name} /></MenuItem>)}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {errors.facility && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.facility}</div>}
                            <Grid container spacing={2}>
                                <Grid item md={6} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="demo-multiple-name-label">Type of Plant *</InputLabel>
                                        <Select
                                            fullWidth
                                            value={form.plantType}
                                            label="Type of Plant"
                                            onChange={(e) => handleChange(e, 'plantType')}
                                        >
                                            {plantTypeValues.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item md={6} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="demo-multiple-name-label">Type of Project *</InputLabel>
                                        <Select
                                            fullWidth
                                            value={form.projectType}
                                            onChange={(e) => handleChange(e, 'projectType')}
                                            label="Type of Project"
                                        >
                                            {projectTypeValues.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    {errors.plantType && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.plantType}</div>}
                                </Grid>
                                <Grid item md={6}>
                                    {errors.projectType && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.projectType}</div>}
                                </Grid>
                            </Grid>
                            {showStpType && (<>
                                <Grid item md={12} style={{ display: 'flex', flexDirection: 'row' }} className='w3-margin-bottom'>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="demo-multiple-name-label">STP Type *</InputLabel>
                                        <Select
                                            fullWidth
                                            value={form.stpType}
                                            label="STP Type"
                                            onChange={(e) => handleChange(e, 'stpType')}
                                        >
                                            {stpTypeValues.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {errors.stpType && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.stpType}</div>}
                            </>
                            )}

                            <Grid container spacing={2}>
                                <Grid item md={6} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                    <TextField
                                        fullWidth
                                        required
                                        name="Plant Capacity *"
                                        value={form.capacity}
                                        onChange={(e) => handleChange(e, 'capacity')}
                                        label="Plant Capacity"
                                    />
                                </Grid>
                                <Grid item md={6} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                    <TextField
                                        fullWidth
                                        required
                                        name="opexCost"
                                        value={form.opexCost}
                                        onChange={(e) => handleChange(e, 'opexCost')}
                                        label="Opex Cost"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                {errors.capacity && <div style={{ marginTop: '-12px' }} className='error-text'>{errors.capacity}</div>}
                                </Grid>
                                <Grid item md={6}>
                                {errors.opexCost && <div style={{ marginTop: '-12px' }} className='error-text'>{errors.opexCost}</div>}
                                </Grid>
                            </Grid>
                            
                            <Grid container item md={12} className='w3-margin-bottom'>
                                <label htmlFor="logo-file">
                                    <input
                                        style={{ display: 'none' }}
                                        required
                                        id="logo-file"
                                        name="logoFile"
                                        type="file"
                                        accept="image/svg+xml"
                                        onChange={handleFileChange}
                                    />

                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <Button
                                                className="w3-margin-top"
                                                color="primary"
                                                variant="outlined"
                                                size="medium"
                                                component="span">
                                                Upload Image
                                            </Button>
                                        </div>
                                    </div>
                                    {errors.uploadedImage && <div className='error-text w3-margin-bottom'>{errors.uploadedImage}</div>}
                                </label>
                            </Grid>
                            <Grid container item md={12}>
                                {selectedImage && <div style={{ position: 'relative' }}>
                                    <Tooltip title="Delete" className='w3-right' style={{ position: 'absolute', top: '8px', right: '6px' }}>
                                        <IconButton className='tm-runcase-drawer-delete-icon' aria-label="delete" onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteMedia(selectedImage)
                                        }}> 
                                            <DeleteIcon/>

                                        </IconButton>
                                    </Tooltip>
                                    <img src={selectedImage} alt="Uploaded file" width="150px" style={{ padding: '5px' }} />

                                </div>}
                            </Grid>
                        </Grid>
                        <Grid container item md={12} className='w3-padding' style={{ alignContent: 'flex-start' }}>
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                                    <DatePicker
                                        label="Start / Installation Date *"
                                        format="DD/MM/YYYY"
                                        required
                                        slotProps={{ textField: { fullWidth: true, error: false, } }}
                                        name="startDate"
                                        value={dayjs(startDate)}
                                        onChange={(newValue) => {
                                            if (disableButton) {
                                                setDisableButton(false)
                                            }
                                            setStartDate(newValue)
                                            setErrors({ ...errors, startDate: null });
                                        }}
                                        onKeyDown={(newValue) => {
                                            setErrors({ ...errors, startDate: null });
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            {errors.startDate && <div style={{ marginTop: '-12px' }} className='error-text w3-margin-bottom'>{errors.startDate}</div>}
                            <Grid item md={12} style={{ display: 'flex', flexDirection: 'row', }} className='w3-margin-bottom'>
                                <TextField
                                    required
                                    fullWidth
                                    label="Daily reports Email"
                                    multiline
                                    rows={4}
                                    value={form.dailyReportsEmail}
                                    onChange={(e) => handleChange(e, 'dailyReportsEmail')}
                                />
                            </Grid>
                            <Grid mb={2}>
                            {errors.dailyReportsEmail && <div className='error-text'>{errors.dailyReportsEmail}</div>}
                            </Grid>
                            <Grid container>
                                <Grid>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox
                                            checked={reportsChecked}
                                            onChange={() => setReportsChecked(prevValue => !prevValue)}
                                        />} label="Daily Report" />
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1} mt={1}>
                                <Grid item md={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    label="Start time"
                                                    fullWidth
                                                    disabled={!reportsChecked}
                                                    sx={{ width: '100%' }}
                                                    value={dayjs(startTime)}
                                                    slotProps={{ textField: { fullWidth: true, error: false, } }}
                                                    onChange={(newValue) => {
                                                        if (newValue === null) {
                                                            setStartTime(null);
                                                        } else {
                                                            setStartTime(newValue);
                                                        }
                                                        const err = { ...errors };
                                                        if (err['startTime']) {
                                                            err['startTime'] = null;
                                                        }
                                                        if (err['timeDifference']) {
                                                            err['timeDifference'] = null;
                                                        }
                                                        setErrors(err);

                                                        if (disableButton) {
                                                            setDisableButton(false);
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                    {errors.startTime && <div className='error-text'>{errors.startTime}</div>}
                                </Grid>
                                <Grid item md={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    label="End time"
                                                    fullWidth
                                                    disabled={!reportsChecked}
                                                    slotProps={{ textField: { fullWidth: true, error: false, } }}
                                                    sx={{ width: '100%' }}
                                                    value={dayjs(endTime)}
                                                    onChange={(newValue) => {
                                                        setEndTime(newValue)
                                                        const err = { ...errors };
                                                        if (err['endTime']) {
                                                            err['endTime'] = null;
                                                        }
                                                        if (err['timeDifference']) {
                                                            err['timeDifference'] = null;
                                                        }
                                                        setErrors(err);
                                                        if (disableButton) {
                                                            setDisableButton(false);
                                                        }
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    </Grid>
                                    {errors.endTime && <div className='error-text'>{errors.endTime}</div>}
                                    {errors.timeDifference && <div className='error-text'>{errors.timeDifference}</div>}
                                </Grid>
                            </Grid>
                        </Grid>
                        {id && (
                            <Grid item md={12} className='w3-margin'>
                                <Paper elevation={1} sx={{ padding: 2 }}>
                                    <Box>
                                        <Typography variant="h6" component="div" className='w3-margin-left'>
                                            Sensors
                                        </Typography>
                                        <ProjectSensors
                                            orderedData={sensorOrderedData}
                                            setOrderedData={setSensorOrderedData}
                                            disableButton={disableButton}
                                            setDisableButton={setDisableButton}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>
                        )}
                        <Grid item md={12} style={{ display: 'flex', flexDirection: 'row'}}>
                            <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: '16px'}} className='w3-margin-bottom'>
                                {(canDelete && id) && (
                                    <Button
                                    variant="outlined"
                                    startIcon={<DeleteIcon />}
                                    color="error"
                                    onClick={() => triggerDelete()}
                                    >
                                    Delete
                                    </Button>
                                )} 
                            </Grid>
                            <Grid item md={6} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '16px' }} className='w3-margin-bottom'>
                                <Button
                                    variant="outlined"
                                    onClick={() => projectQueryParams ? navigate(`/client/${client_id}/projects?${projectQueryParams?.toString()}`) : navigate(`/client/${client_id}/projects`)}

                                >
                                    Cancel
                                </Button>
                                    {(canEdit || canCreate) && <Button
                                    disabled={disableButton}
                                    variant="contained"
                                    onClick={handleSubmit}
                                    style={{ marginLeft: '10px' }}
                                >
                                    Submit</Button>}
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    {showConfirmUploadDialog
                        ? (
                            <ConfirmDialog
                                handleCancel={() => {
                                    setShowConfirmUploadDialog(false)
                                }}
                                handleOk={handleConfirmUpload}
                                message={'Are you sure to Upload Image?'}
                            />
                        ) : null}
                        {showConfirmDialog
                        ? (
                            <ConfirmDialog
                                handleCancel={() => {
                                    setSelectedItem({})
                                    setShowConfirmDialog(false)
                                }}
                                handleOk={handleDeleteProject}
                                message={'Are you sure to delete this ?'}
                            />
                        ) : null}
                </Grid>
            </Grid>
        </Paper>
    )
}