import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TextField, InputLabel, FormControl } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { createInvite, updateInvite, updateJoinedUser } from '../../Redux/actions';
import AlertDialog from '../Common/AlertDialog'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    padding: 3
};
export default function InviteModal({ fetchData, handleClose, editData, open, handleModalMsg }) {

    const dispatch = useDispatch();
    const [queryParams, setQueryParams] = useSearchParams();

    const userRoles = ['Admin', 'spoc'];
    const state = useSelector(st => st);
    const { user } = state;

    const formData = {
        name: '',
        emailId: '',
        role: ''
    }

    const error = {};
    const [errors, setErrors] = useState(error);
    const [form, setForm] = useState(formData);
    const [disableButton, setDisableButton] = useState(false);

    const inputLabel = React.useRef(null);

    useEffect(() => {
        if (editData) {
            const { name, emailId, user_id, role, id, status } = editData
            const flowData = {
                name,
                emailId: queryParams.get('tab') === 'invited' ? emailId : user_id,
                role,
                id,
                status
            }
            setForm(flowData)
        }
    }, [editData])

    const validateData = () => {
        const err = Object.assign({}, errors);

        let hasErrors = false;

        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        const emailValidation = emailRegex.test(form?.emailId);
        if (!emailValidation) {
            hasErrors = true;
            err.emailId = 'Enter a valid email id';
        }
        for (var key in form) {
            if ((form.hasOwnProperty(key) && form[key] === '') || (form[key].trim() === '')) {
                hasErrors = true;
                err[key] = 'This field is mandatory'
            }
        }

        if (hasErrors) {
            setErrors(err);
            return false;
        }
        return true;
    };

    const updateResponse = (resp, message) => {
        const res = resp && resp.data;
        let msg = res?.message;
        if (msg) {
            let type = "fail";
            if (res && res.success) {
                msg = message;
                type = "success";
            }
            fetchData();
            setForm(formData)
            handleModalMsg(msg, type)
        }
        handleClose()
    };

    const handleSubmit = () => {
        setDisableButton(true)
        const checkForm = validateData();

        if (!checkForm) {
            return;
        }
        form['type'] = 'greenInvite'


        if (form.id) {
            if (queryParams.get('tab') === 'invited') {
                dispatch(updateInvite(form.id, form)).then((res) => {
                    setDisableButton(false)
                    updateResponse(res, 'User updated successfully');
                })
            } else {
                dispatch(updateJoinedUser(form.id, form)).then((res) => {
                    setDisableButton(false)
                    updateResponse(res, 'User updated successfully');
                })
            }

        } else {
            dispatch(createInvite(form)).then((resp) => {
                setDisableButton(false)
                if (resp.status === 200 || resp.status === 201) {
                    updateResponse(resp, 'User invited successfully');
                }
                if(!resp?.data?.data?.success) {
                    updateResponse(resp, resp?.data?.data?.message);
                }

            })
        }

    };

    const handleUserStatus = () => {
        const status = editData && editData?.status === 'Joined' ? 'Inactive' : editData.user_id != null ? 'Joined' : 'Invited';
        form.type = 'greenInvite'
        Object.assign(form, { status });
        dispatch(updateJoinedUser(form.id, form)).then((res) => {
            updateResponse(res, `User ${editData?.status === 'Joined' ? 'Deactivated' : 'Activated'}`);
        })
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        const data = Object.assign({}, form);
        data[name] = value;
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
        if (name === 'emailId') {
            data[name] = value.toLowerCase();
          }
        setForm(data);
        if (disableButton) {
            setDisableButton(false)
        }
    };
    return (
        <Grid container>
            <Grid container item md={12} style={{ display: 'flex', flexDirection: 'row', }}>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <p>{editData ? "Edit User" : "Invite User"}</p>
                        <Grid container className='w3-padding'>
                            <Grid item md={12}>
                            </Grid>
                            <Grid container item md={12}>
                                <Grid item md={12} className='w3-margin-bottom' style={{ display: 'flex', flexDirection: 'row', }}>

                                    <Grid item md={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            label="Name"
                                            name="name"
                                            value={form.name}
                                            onChange={handleChange} />
                                        {errors.name && <div className='error-text '>{errors.name}</div>}
                                    </Grid>

                                </Grid>
                                <Grid item md={12} className='w3-margin-bottom' style={{ display: 'flex', flexDirection: 'row', }}>

                                    <Grid item md={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            label="Email"
                                            name="emailId"
                                            disabled={queryParams.get('tab') === 'invited' ? editData?.emailId : editData?.user_id}
                                            value={form?.emailId}
                                            onChange={handleChange}
                                        />
                                        {errors.emailId && <div className='error-text '>{errors.emailId}</div>}
                                    </Grid>
                                </Grid>
                                <Grid item md={12} className='w3-margin-bottom' style={{ display: 'flex', flexDirection: 'row', }}>

                                    <Grid item md={12}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel ref={inputLabel} id="roleLabel">
                                                Role*
                                            </InputLabel>
                                            <Select
                                                labelId="roleLabel"
                                                id="demo-simple-select"
                                                value={form.role}
                                                label="Role"
                                                name="role"
                                                fullWidth
                                                required
                                                onChange={handleChange}

                                            >
                                                {userRoles?.map(item => <MenuItem key={item.toLowerCase()} value={item.toLowerCase()}>{item}</MenuItem>)}
                                            </Select>
                                        </FormControl>
                                        {errors.role && <div className='error-text '>{errors.role}</div>}

                                    </Grid>
                                </Grid>

                                <Grid container style={{ margin: '10px 0' }}>
                                    <Grid item md={6}>
                                        {(user?.data?.user?.uniqueId !== form.emailId && editData && (editData?.status !== 'Invited') && (editData?.authUser?.status !== 'Inactive')) && <Grid item>
                                            {(editData?.status === 'Joined' || editData?.status === 'Inactive') && (<Button variant="contained" className='w3-deep-purple' onClick={handleUserStatus}>{editData?.status === 'Inactive' ? 'Activate User' : 'Deactivate User'}</Button>)}
                                        </Grid>}
                                    </Grid>

                                    <Grid item md={6} style={{ display: 'flex', justifyContent: 'end' }}>
                                        <Grid item>
                                            <Button variant="outlined" className='w3-margin-right' onClick={handleClose}>Cancel</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                disabled={editData?.authUser?.status === 'Inactive' || disableButton}
                                                variant="contained"
                                                className=''
                                                onClick={handleSubmit}
                                            >
                                                {editData ? 'Submit' : 'Invite'}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Grid>
        </Grid>
    )
}