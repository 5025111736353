import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import DataTable from '../Common/DataTable';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserInviteData, resendInvite, deleteInvite, getJoinedUserData } from '../../Redux/actions';
import { CircularProgress, Tooltip, IconButton, Chip, Box } from '@mui/material';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ConfirmDialog from '../Common/ConfirmDialog';

import { red, green } from '@mui/material/colors';
import { Edit, Delete, Refresh } from '@mui/icons-material';

import SearchBox from '../Common/SearchBox';
import { Can } from '../../Context/Can';
import { DeleteIcon } from '../Common/TableUI';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'copy-to-clipboard';
import AlertDialog from '../Common/AlertDialog';

export default function ManageUser() {

    const { client_id } = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState(null)
    const [queryParams, setQueryParams] = useSearchParams();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const showDeleteIcon = ['Invited', 'Inviting', 'Invite-Error']
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [showLoader, setShowLoader] = useState(false)
    const [isCopied, setIsCopied] = useState(false);

    const [alertMessage, setAlertMessage] = useState();
    const [msgType, setMsgType] = useState("success");
    // const [currentTab, setCurrentTab] = useState(queryParams.tab || 0);

    const handleChangeTab = (event, newValue) => {
        // setCurrentTab(newValue);
        updateQueryParams({ tab: newValue });
    };

    const fetchData = () => {
        const params = {
            title: queryParams.get("search")
        }
        setShowLoader(true)
        if (queryParams.get('tab') === 'invited') {
            dispatch(getUserInviteData(client_id, params)).then(res => {
                const response = res.data.data;
                setData(response);
            })
            setShowLoader(false)
            return
        } else if (queryParams.get('tab') === 'joined') {
            dispatch(getJoinedUserData(client_id, params)).then(res => {
                const response = res?.data?.data;
                setData(response);
                setShowLoader(false)
            })
        }



    }

    useEffect(() => {
        if (!queryParams.get('tab')) {
            setQueryParams({ tab: 'joined' });
        }
        fetchData()
    }, [ queryParams, client_id])


    const goToRoutes = (routeApi) => {
        navigate(routeApi, { replace: true });
    }

    const updateQueryParams = (params) => {
        const nParams = Object.assign({}, queryParams, params);
        setQueryParams(nParams);
    }

    const handleSearch = (value) => {
        updateQueryParams({ search: value, tab: queryParams.get('tab') });
    }

    const handleResend = (user) => {
        dispatch(resendInvite(user)).then((res) => {
        })
    }

    const triggerDelete = (user) => {
        setShowConfirmDialog(true);
        setSelectedItem(user);
    }
    const deleteUserInvite = () => {
        setShowConfirmDialog(false);
        dispatch(deleteInvite(selectedItem)).then((res) => {
           if(!res.data.success) {
               setAlertMessage(res.data.message);
               setMsgType('fail')
           }
            fetchData();
        })
    }

    const handleCopyInvite = (url) => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        copy(url);
    };

    const getStatusClassName = (item) => {
        if (item?.authUser?.status === 'Inactive') {
            return 'w3-red';
        } else if (item.status === 'Joined') {
            return 'w3-green';
        } else if (item.status === 'Invited') {
            return 'w3-yellow';
        } else {
            return 'w3-red'; 
        }
    }
    
    const handleDialogClose = () => {
        setAlertMessage(null);
    };

    let userList = [];
    if (showLoader) {
        userList = (
            <TableRow>
                <TableCell colSpan={6} align="center">
                    <CircularProgress />
                </TableCell>
            </TableRow>
        )
    } else if (data?.length > 0) {
        userList = (data?.length > 0 && data?.map((item) => (
            <TableRow key={item?.id}
                onMouseEnter={() => {
                    setHoveredRowId(item?.id);
                }}
                onMouseLeave={() => {
                    setHoveredRowId(null);
                }}
            >
                <TableCell>{queryParams.get('tab') === 'invited' ? item?.name : item?.name}</TableCell>
                <TableCell style={{ maxWidth: '250px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{queryParams.get('tab') === 'invited' ? item?.emailId : item?.user_id}</TableCell>
                {/* <TableCell>{Object.keys(item?.json)
                .filter(key => item?.json[key])
                .join(', ')}</TableCell> */}
                <TableCell>
                <Chip
                    size="small"
                    className={getStatusClassName(item)}
                    label={item?.authUser?.status === 'Inactive' ? 'Deactivated' : item.status}
                />
                </TableCell>
                <TableCell>
                 <Box component="div" display="flex" justifyContent={"right"}>

                    <Can do="manage" on="EditClientUser">
                        <Tooltip title="Edit">
                            <IconButton aria-label="edit"
                                style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    localStorage.setItem('customerUsersQueryParams', queryParams);
                                    navigate(`/client/${client_id}/users/invite/edit/${item?.id}`, { state: { status: queryParams.get('tab') } })
                                }}>
                                <Edit className="cursor-pointer"
                                    style={{ color: 'black'}}
                                />
                            </IconButton>
                        </Tooltip>
                    </Can>
                    <Can do="manage" on="DeleteClientUser">
                        {showDeleteIcon.includes(item?.status) && <Tooltip title="Delete">
                            <IconButton aria-label="delete"
                                style={{ opacity: hoveredRowId === item?.id ? 1 : 0 }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    triggerDelete(item?.id)
                                }}
                            > 
                                <DeleteIcon/>

                            </IconButton>
                        </Tooltip>}
                    </Can>
                    {item?.status === 'Invite-Error' && <Can do="manage" on="ClientUserInvite">
                    <Tooltip title="Resend Email">
                        <IconButton aria-label="resend"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleResend(item);
                            }}>
                            <Refresh className="cursor-pointer"
                                style={{ color: green[500] }}
                            />
                        </IconButton>
                    </Tooltip></Can>}

                    {item?.status === 'Invited' && (
                        <>
                            <Tooltip 
                            title={isCopied ? 'Link copied!' : 'Copy invite link'}
                            arrow
                            >
                                <IconButton aria-label="copy"
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0 }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const url = window.location.href;
                                        const parsedUrl = new URL(url);
                                        const host = parsedUrl.origin;
                                        handleCopyInvite(host + `/invite/${item?.token}`);
                                    }}> 
                                    <ContentCopyIcon/>
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                    </Box>
                </TableCell>
            </TableRow>
        )))
    } else {
        userList = (<TableRow>
            <TableCell colSpan={6} align="center">
                <h5 className='w3-text-grey'>
                    No Data Found
                </h5>
            </TableCell>
        </TableRow>)
    }

    return (
        <Grid container>
            <Grid item md={12}>
                <Grid container item md={12} >
                    <Grid item container md={12} paddingY={2} paddingX={3}>
                        <Grid item  md={6}>
                            <Can do="manage" on="ClientUserInvite">
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        if (localStorage.getItem('customerUsersQueryParams')) {
                                            localStorage.removeItem('customerUsersQueryParams');
                                        }
                                        navigate(`/client/${client_id}/users/invite`, { state: { status: queryParams.get('tab') } });
                                    }}
                                >
                                    Invite User
                                </Button>
                            </Can>
                        </Grid>
                        <Grid item container md={6} justifyContent={"flex-end"}>
                            <h6 style={{ paddingRight: '5px', fontSize: '15px' }}>Search By: </h6>
                            <SearchBox
                                value={queryParams.get('search') || ''}
                                placeholder='User Name'
                                search={handleSearch}
                            />
                        </Grid>
                    </Grid>
                    <Tabs value={queryParams.get('tab')} onChange={handleChangeTab} style={{ marginLeft: '24px' }}>
                        <Tab label="Joined Users" value="joined" />
                        <Tab label="Invited Users" value="invited" />
                    </Tabs>
                </Grid>
                <div className='w3-padding-large'>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='client-table-head'> Name</TableCell>
                                    <TableCell className='client-table-head'>Email Id</TableCell>
                                    {/* <TableCell>Permissions</TableCell> */}
                                    <TableCell className='client-table-head'>Status</TableCell>
                                    <TableCell className='client-table-head'></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userList}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Grid>
            {showConfirmDialog
                ? (
                    <ConfirmDialog
                        handleCancel={() => {
                            setSelectedItem({})
                            setShowConfirmDialog(false)
                        }}
                        handleOk={deleteUserInvite}
                        message={'Are you sure to delete this user?'}
                    />
                ) : null}
                {alertMessage ? (
                <AlertDialog
                    handleClose={() => setAlertMessage(null)}
                    message={alertMessage}
                    type={msgType}
                    handleDialogClose={handleDialogClose}
                />) : null}
        </Grid>
    )
}




