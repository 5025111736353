import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "../Components/Common/Landing";
import Dashboard from "../Components/Dashboard/Dashboard";
import Assets from "../Components/Assets";
import Facilities from "../Components/Facilities/Facilities";
import Projects from "../Components/Projects/Projects";
import Users from "../Components/Users/ManageUser";
import ClientSettings from "../Components/ClientSettings";
import Incidents from "../Components/Incidents";
import { useEffect, useState } from 'react';
// import UserManagement from "../Components/UserManagement";
import Settings from "../Components/Settings";
import CreateClient from "../Components/Dashboard/CreateClient";
import ManageLocation from "../Components/Locations/ManageLocation";
import Location from "../Components/Locations/Location";
import ManageFacilities from "../Components/Facilities/ManageFacilities";
import ManageProjects from "../Components/Projects/ManageProjects";
import Meters from "../Components/Meters/Meters";
import ManageMeters from "../Components/Meters/ManageMeters";
import UserManagement from "../Components/UserManagement/UserManagement";
import Box from '@mui/material/Box';
import ClientListing from "../Components/ClientListing";
import User from "../Components/Users/User";
import ManageUser from "../Components/Users/ManageUser";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../Components/Common/AlertDialog";
import { setStoreData } from "../Redux/actions";
import Report from "../Components/Report/Report";
import CreateReport from "../Components/Report/CreateReport";
import { Can } from '../Context/Can';
import AssetsDetails from "../Components/AssetsDetails";
import LiveView from "../Components/Live";
import ESGView from "../Components/AiDashboard/ESGView";
import ReportView from "../Components/AiDashboard/ReportView";
import GeoTagging from "../Components/AiDashboard/GeoTagging";
import WaterBalance from "../Components/AiDashboard/SustainabilityDasboard/WaterBalance";
import { Container } from "@mui/material";
import { SystemLog } from "../Components/Dashboard/SystemLogs";
import ProjectCommon from "../Components/Projects/ProjectCommon";
import MeterCommon from "../Components/Meters/MeterCommon";
import CalibrationReport from "../Components/Meters/CalibrationReport";
import CalibrationForm from "../Components/Meters/CalibrationForm";
import Tickets from "../Components/Tickets/Tickets";
import TicketDetails from "../Components/Tickets/TicketDetails";
import UpdateCacheData from "../Components/UpdateCache";
import SiteVisitReport from "../Components/Projects/SiteVisitReport";
import SiteVisitForm from "../Components/Projects/SiteVisitForm";
import DigitalisationCommon from "../Components/Facilities/DigitalisationCommon";

const PageNotFound = ({ user, currentUserRoles }) => {
  const auth_token = localStorage.getItem('accessToken')
    if (window.location.pathname === '/login' || (window.location.pathname.startsWith('/client') && !auth_token)) {
      return null;
    }
    return <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: '500px' }}>
      <h4>Page Not Found</h4>
      {currentUserRoles?.length > 0 && <p> The url you are searching for doesn't exist</p>}
      {currentUserRoles?.length === 0 && <p>You are not associated with any clients. Please contact your system admin.</p>}
    </div>
}

const NoFacilities = () => {
    return <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', height: '500px' }}>
      <h4>No Facilities associated with this Client</h4>
    </div>
}

export function AppRouter({user, currentUserRoles}) {

  const path = window.location.pathname;
  const [userMode, setUserMode] = useState('not-set');
  
  const settingsTabs = ['general', 'report', 'locations', 'facilities'];
  const meterTabs = ['trending-analysis', 'online-monitoring', 'calibration-report', 'edit', 'create'];
  const projectTabs = ['live', 'summary-table', 'report-view', 'edit', 'create', 'site-visit-report'];
  const digitalisationTabs = ['live', 'summary-table', 'report-view', 'water-balance', 'tickets', 'site-visit-report', 'calibration-report', 'edit', 'create']
  
  const checkTabs = (tabs, path) => {
    return tabs.some(tab => {
      const regex = new RegExp(`/${tab}(\/|$)`);
      const match = regex.test(path);
      return match;
    });

  };

  const state = useSelector(st => st);
  const { role } = state

  useEffect(() => {
    const userRoles = role?.data?.data;

    if (!userRoles || userRoles.length == 0) {
      return;
    }
    const hasGreen = userRoles.some(item => item.scope == 'green');
    setUserMode(hasGreen ? 'green' : 'client');
  });

  // Redirect to "Page Not Found" if path includes '/meters' and userMode is 'client'
  if (path.includes('/meters') && userMode === 'client') {
    window.location.href = '/page-not-found';
  }
  
  const parts = path.split('/');
  const checkAction = (path) => {
    const actionIndex = parts.indexOf('settings') + 2;
    if (actionIndex >= 0 && actionIndex < parts.length) {
      const action = parts[actionIndex];
      const validUrl = ['edit', 'create']
      return !validUrl.includes(action);
    }
    return false;
  };
  
  if ((path.includes('/settings') || path.includes('/meters') || path.includes('/projects')) && path.includes('/client') || path.includes('/digitalisation')) {
    const validAction = checkAction(path);
    const isOnSettings = checkTabs(settingsTabs, path);
    const hasProjectsOrMetersOrDigitalisation = path.includes('projects') || path.includes('meters') || path.includes('digitalisation');
    const lastPath = parts[parts.length - 1];
    const acceptPath = ['projects', 'meters', 'digitalisation'];
    const allPaths = [...settingsTabs, ...projectTabs, ...meterTabs, ...digitalisationTabs];
    const pathFound = parts.some(item => allPaths.includes(item));
    if ((hasProjectsOrMetersOrDigitalisation && !acceptPath.includes(lastPath) && !pathFound) || (validAction && isOnSettings) || (!isOnSettings && path.includes('/settings'))) {
      const invalidSettingsTab = settingsTabs.find(tab => path.includes(tab));
      const invalidMeterTab = meterTabs.find(tab => path.includes(tab));
      const invalidProjectTab = projectTabs.find(tab => path.includes(tab));
      const invalidDigitalisationTabs = digitalisationTabs.find(tab => tab.includes(tab))
      const isOnSettingsNotValidPath = !isOnSettings && path.includes('/settings')

      if (invalidSettingsTab || invalidMeterTab || invalidProjectTab || isOnSettingsNotValidPath || invalidDigitalisationTabs) {
        window.location.href = '/page-not-found';
      }
    }
  }
  

  const dispatch = useDispatch()
  const alertMessage = useSelector(state => state.alertMessage)
  return (
    <BrowserRouter>
      <Landing />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          marginTop:'72px'
        }}
      >
        <Routes>
          <Route path="/" element={<Can I="view" a="Dashboard"><Dashboard /></Can>} />
          <Route path="/dashboard" element={<Can I="view" a="Dashboard"><Dashboard /></Can>} />
          <Route path="/client-listing" element={<Can I="view" a="Client"><ClientListing /></Can>} />
          <Route path="/client/:client_id/assets" element={<Can I="view" a="Assets"><Assets /></Can>} />
          {/* <Route path="/client/:client_id/assets/:pid" element={<Can I="view" a="Assets"><AssetsDetails /></Can>} /> */}
          <Route path="/client/:client_id/live" element={<Can I="view" a="Live"><LiveView /></Can>} />
          <Route path="/client/:client_id/esg" element={<Can I="view" a="Esg"><ESGView /></Can>} />
          <Route path="/client/:client_id/report-view" element={<Can I="view" a="ReportView"><ReportView /></Can>} />
          <Route path="/client/:client_id/geo-tagging" element={<Can I="view" a="GeoTagging"><GeoTagging /></Can>} />
          <Route path="/client/:client_id/water-balance-report" element={<Can I="view" a="WaterBalanceReport"><WaterBalance /></Can>} />

          <Route path="/client/:client_id/locations" element={<Can I="view" a="Location"><ManageLocation /></Can>} />
          <Route path="/client/:client_id/report" element={<Can I="view" a="Report"><Report /></Can>} />
          <Route path="/client/:client_id/report/create" element={<Can I="view" a="CreateReport"><CreateReport /></Can>} />
          <Route path="/client/:client_id/report/edit/:id" element={<Can I="view" a="EditReport"><CreateReport /></Can>} />

          <Route path="/client/:client_id/locations/edit/:location_id" element={<Can I="view" a="EditLocation"><Location /></Can>} />
          <Route path="/client/:client_id/facilities" element={<Can I="view" a="Facility"><Facilities /></Can>} />
          <Route path="/client/:client_id/projects" element={<Can I="view" a="Project"><Projects /></Can>} />

          <Route path="/client/:client_id/meters" element={<Can I="view" a="Meter"><Meters /></Can>} />
          <Route path="/client/:client_id/users" element={<Can I="view" a="Users"><Users /></Can>} />

          <Route path="/client/:client_id/settings/:tabId/:action?/:id?" element={<ClientSettings />}></Route>

          <Route path="/incidents" element={<Can I="view" a="Incidents"><Incidents /></Can>} />
          <Route path="/user-management" element={<Can I="view" a="UserManagement"><UserManagement /></Can>} />
          <Route path="/tickets" element={<Can I="view" a="Tickets"><Tickets /></Can>} />
          <Route path="/tickets/details/:ticket_id" element={<Can I="view" a="TicketDetails"><TicketDetails /></Can>} />
          <Route path="/settings" element={<Can I="view" a="Settings"><Settings /></Can>} />
          <Route path="/system-logs" element={<Can I="view" a="Settings"><SystemLog /></Can>} />
          <Route path="/client/create" element={<Can I="view" a="CreateClient"><CreateClient /></Can>} />
          <Route path="/client/:client_id/locations/create" element={<Can I="view" a="CreateLocation"><Location /></Can>} />
          <Route path="/client/:client_id/facilities/create" element={<Can I="view" a="CreateFacility"><ManageFacilities /></Can>} />
          <Route path="/projects/new-project" element={<Can I="view" a="Project"><ManageProjects /></Can>} />
          <Route path="/user-management" element={<Can I="view" a="User"><ManageUser /></Can>} />
          <Route path="/client/:client_id/projects/create" element={<Can I="view" a="CreateProject"><ManageProjects /></Can>} />
          <Route path="/client/:client_id/meters/create" element={<Can I="view" a="CreateMeter"><ManageMeters /></Can>} />
          <Route path="/client/:client_id/users/invite" element={<Can I="view" a="CreateUsers"><User /></Can>} />
          <Route path="/client/:client_id/facilities/edit/:id" element={<Can I="view" a="EditFacility"><ManageFacilities /></Can>} />
          {/* <Route path="/client/:client_id/meters/edit/:id" element={<Can I="view" a="EditMeter"><ManageMeters /></Can>} /> */}
          <Route path="/client/:client_id/users/invite/edit/:id" element={<Can I="view" a="EditUsers"><User /></Can>} />
          <Route path="/client/:client_id/user-settings" element={<Can I="view" a="Settings"><Settings /></Can>} />
          <Route path="/invite/:token" element={<Can I="view" a="Client"><ClientListing /></Can>} />
          <Route path="/system-dashboard" element={<Can I="view" a="Client"><Dashboard type="System" /></Can>} />
          <Route path="/update-cache" element={<Can I="view" a="UpdateCache"><UpdateCacheData /></Can>} />

          <Route path="/client/:client_id/client-tickets" element={<Can I="view" a="Tickets"><Tickets /></Can>} />
          <Route path="/client/:client_id/client-tickets/:ticket_id" element={<Can I="view" a="TicketDetails"><TicketDetails /></Can>} />

          <Route path="/client/:client_id/projects/:tabId/:id?" element={<ProjectCommon />}></Route>
          <Route path="/client/:client_id/meters/:tabId/:id?" element={<Can I="view" a="MeterCommon"><MeterCommon /></Can>}></Route>
          <Route path="/client/:client_id/meters/calibration-report/view/:id" element={<Can I="view" a="CalibrationReport"><CalibrationReport /></Can>}></Route>
          <Route path="/client/:client_id/meters/calibration-report/edit/:calibration_id" element={<Can I="view" a="CalibrationForm"><CalibrationForm /></Can>}></Route>
          <Route path="/client/:client_id/meters/calibration-report/:id/create" element={<Can I="view" a="CalibrationReport"><CalibrationForm /></Can>}></Route>

          <Route path="/client/:client_id/projects/site-visit-report/:pid/create" element={<Can I="view" a="SiteVisitForm"><SiteVisitForm /></Can>}></Route>
          <Route path="/client/:client_id/projects/site-visit-report/:pid/view/:id" element={<Can I="view" a="SiteVisitReport"><SiteVisitReport /></Can>}></Route>
          <Route path="/client/:client_id/projects/site-visit-report/:pid/edit/:site_visit_id" element={<Can I="view" a="SiteVisitForm"><SiteVisitForm /></Can>}></Route>

          <Route path="/client/:client_id/digitalisation" exact={true} element = {<NoFacilities />}></Route>
          <Route path="/client/:client_id/digitalisation/:facilityId/:tabId?" element={<DigitalisationCommon />}></Route>
          <Route path="/client/:client_id/digitalisation/:facilityId/:tabId?/details/:ticket_id" element={<TicketDetails />}></Route>
          
          <Route path="/client/:client_id/digitalisation/:facilityId/site-visit-report/create" element={<Can I="view" a="SiteVisitForm"><SiteVisitForm /></Can>}></Route>
          <Route path="/client/:client_id/digitalisation/:facilityId/site-visit-report/:pid/create" element={<Can I="view" a="SiteVisitForm"><SiteVisitForm /></Can>}></Route>
          <Route path="/client/:client_id/digitalisation/:facilityId/site-visit-report/:pid/view/:id" element={<Can I="view" a="SiteVisitReport"><SiteVisitReport /></Can>}></Route>
          <Route path="/client/:client_id/digitalisation/:facilityId/site-visit-report/:pid/edit/:site_visit_id" element={<Can I="view" a="SiteVisitForm"><SiteVisitForm /></Can>}></Route>

          <Route path="/client/:client_id/digitalisation/:facilityId/calibration-report/create" element={<Can I="view" a="DigiCalibrationReport"><CalibrationForm /></Can>}></Route>
          <Route path="/client/:client_id/digitalisation/:facilityId/calibration-report/:id/create" element={<Can I="view" a="DigiCalibrationReport"><CalibrationForm /></Can>}></Route>
          <Route path="/client/:client_id/digitalisation/:facilityId/calibration-report/view/:id" element={<Can I="view" a="DigiCalibrationReport"><CalibrationReport /></Can>}></Route>
          <Route path="/client/:client_id/digitalisation/:facilityId/calibration-report/edit/:calibration_id" element={<Can I="view" a="DigiCalibrationForm"><CalibrationForm /></Can>}></Route>
          

          <Route path="*" exact={true} element = {<PageNotFound user={user} currentUserRoles={currentUserRoles} />}></Route>
        </Routes>
        {alertMessage &&
          (
            <AlertDialog
              handleClose={() => {
                if (alertMessage?.handleClose) {
                  alertMessage.handleClose()
                }
              }}
              message={alertMessage?.msg}
              type={alertMessage?.type}
              handleDialogClose={() => dispatch(setStoreData('alertMessage', null))}
            />
          )
        }
      </Box>
    </BrowserRouter>
  );
}  
