import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSiteVisitReport, getProjectById } from '../../Redux/actions';
import { useEffect, useState } from 'react';
import { Can } from '../../Context/Can';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

import VisibilityIcon from '@mui/icons-material/Visibility';

import { Tooltip, IconButton, CircularProgress, Button } from '@mui/material';
import { Grid, Card, TableRow, TableContainer, TableCell, TableHead, Table, TableBody } from '@mui/material';
import { Edit } from '@mui/icons-material';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import ImagePlaceholder from '../../Images/image-placeholder.png'
import { removeNonValueFilter } from '../../utils';

export default function SiteVisitReportList({ dPids }) {
    const { id, client_id, facilityId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [siteVisitReportList, setSiteVisitReportList] = useState([]);
    const [projectData, setProjectData] = useState([]);
    const [hoveredRowId, setHoveredRowId] = useState(null);
    const [showLoader, setShowLoader] = useState(true);

    const [expandedRows, setExpandedRows] = useState([]);

    const [selectedImage, setSelectedImage] = useState(null);
    const [openImageDialog, setOpenImageDialog] = useState(false);


    const [permissionNotFound, setPermissionNotFound] = useState(false)
    const [queryParams, setQueryParams] = useSearchParams();
    const existingParams = Object.fromEntries([...queryParams])
    const [itemsPerPage, setItemsPerPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);

    useEffect(() => {
        fetchData()
    }, [id, client_id, dPids])

    const fetchData = (previousPage) => {
        const form = {
            ...existingParams,
            page: previousPage? previousPage : existingParams?.page,
        }
        if( dPids?.length > 0 ) {
            setShowLoader(true);
            dispatch(getAllSiteVisitReport(client_id, dPids, form)).then((res) => {
                if (!res.data.data) {
                    setShowLoader(false);
                    return;
                }
                
                const allSiteVisitReports = res.data.data;
                setSiteVisitReportList(allSiteVisitReports);
                setItemsPerPage(res?.data?.itemsPerPage)
                setCurrentPage(res?.data?.currentPage)
                setShowLoader(false);
            });
        }else if ( id ){
            setShowLoader(true);
            dispatch(getAllSiteVisitReport(client_id, id, form)).then((res) => {
                if(!res.data.success && !res.data.permissionFound) {
                    setPermissionNotFound(true);
                }
                if (!res.data.data) {
                    setShowLoader(false);
                    return
                }
                setSiteVisitReportList(res.data.data);
                setItemsPerPage(res?.data?.itemsPerPage)
                setCurrentPage(res?.data?.currentPage)
                setShowLoader(false);
            });
            
            dispatch(getProjectById(client_id, id)).then(res =>{
                if (!res.data.projectData) {
                    return
                }
                setProjectData(res.data.projectData)
            })
        } else {
            setSiteVisitReportList([])
            setShowLoader(false);
        }
    }

    useEffect(() => {
        if (existingParams?.page > 1) {
            fetchData();
        } else if (!existingParams?.page) {
            updateQueryParams({ page: 1 });
        }
    }, [queryParams]);


    const siteVisitColumn = [
        'SI No',
        ...(facilityId ? ['Project'] : []),
        'Site Visit Type',
        'Description',
        'Attachment URL',
        'Visited Date',
        'Report Time',
        'Engineer Incharge',
        'Ticket Number',
        'Action',
    ];

    const handleExpandedViewClick = (selectedItem) => {
        facilityId ? navigate(`/client/${client_id}/digitalisation/${facilityId}/site-visit-report/${selectedItem.pid}/view/${selectedItem.id}`) : navigate(`/client/${client_id}/projects/site-visit-report/${id}/view/${selectedItem.id}`, { state: { selectedItem } });
    }

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpenImageDialog(true);
    };

    const handleCloseImageDialog = () => {
        setOpenImageDialog(false);
        setSelectedImage(null);
    };

    const updateQueryParams = (params) => {
        const nParams = removeNonValueFilter(Object.assign({}, existingParams, params));
        setQueryParams(nParams);
    }

    const handleForwardButtonClick = () => {
        const currentPage = parseInt(existingParams?.page) || 1;
        const nextPage = currentPage + 1;
        updateQueryParams({ page: nextPage });
    };

    const handleBackwardButtonClick = () => {
        const currentPage = parseInt(existingParams?.page) || 1;
        const previousPage = currentPage > 1 ? currentPage - 1 : 1;
        updateQueryParams({ page: previousPage });
        if( previousPage === 1 ) {
            fetchData(previousPage);
        }
    };

    let siteVisitReportTable;
    if (showLoader) {
        siteVisitReportTable = (
            <TableRow>
                <TableCell colSpan={siteVisitColumn.length} style={{ textAlign: 'center', paddingTop: '50px' }}>
                    <CircularProgress />
                </TableCell>
            </TableRow>
        );


    } else if (siteVisitReportList?.length > 0) {
        siteVisitReportTable = (siteVisitReportList?.sort((a, b) => b.id - a.id)?.map((item, index) => {
            const reportDescription = item?.descriptionNew;
            const descriptionList = reportDescription.split('<br>').filter(str => str.trim() !== '');
            
            let truncatedDescriptionList = descriptionList.slice(0, 5);

            const tickets = item?.ticketNumber;
            const ticketsArr = tickets?.split(',') || [];
            const ticketNumber = ticketsArr.filter(item => item !== null && !item.startsWith('null'));
            
            return <TableRow key={index}
                onMouseEnter={() => {
                    setHoveredRowId(item?.id);
                }}
                onMouseLeave={() => {
                    setHoveredRowId(null);
                }}>
                <TableCell>{(currentPage - 1) * itemsPerPage + index + 1}</TableCell>
                {facilityId && (<TableCell>{item?.project?.projname}</TableCell>)}
                <TableCell>{item?.siteVisitType}</TableCell>
                <TableCell>
                <ul style={{ maxHeight: '100px', overflowY: 'auto' }}>
                    {truncatedDescriptionList.map((description, idx) => (
                        <li key={idx}>{description}</li>
                    ))}
                </ul>
            </TableCell>
                <TableCell>
                    {item?.media?.map((val, index) => {
                        const dynamicField = item?.dynamicFieldsData && item?.dynamicFieldsData[index];
                        if (val.ref_id === 0) {
                            return false
                        }
                        return (
                            <div key={index}>
                                {dynamicField?.images.map((image, imageIndex) => (
                                    <img
                                        key={imageIndex}
                                        src={ImagePlaceholder}
                                        alt="Attachment"
                                        style={{ cursor: 'pointer', maxWidth: '20px', marginRight: '5px' }}
                                        onClick={() => handleImageClick(image.signedUrl)}
                                    />
                                ))}
                            </div>
                        );
                    })}
                </TableCell>

                <TableCell>{moment(item?.visitedDate).format('YYYY-MM-DD')}</TableCell>
                <TableCell>{moment(item?.reportTime).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                <TableCell>{item?.engineerVisiting}</TableCell>
                <TableCell>{ticketNumber.join(',')}</TableCell>


                <TableCell>
                    <Grid style={{ display: 'flex' }}>
                        <Tooltip title="View">
                            <IconButton aria-label="view"
                                style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px', marginRight: '10px' }}
                                onClick={(e) => {
                                    localStorage.setItem('siteVisitQueryParams', queryParams);
                                    handleExpandedViewClick(item)
                                }}
                            >
                                <VisibilityIcon className="cursor-pointer" />
                            </IconButton>
                        </Tooltip>
                        <Can do="manage" on="EditSiteVisitReport">
                            <Tooltip title="Edit">
                                <IconButton aria-label="edit"
                                    style={{ opacity: hoveredRowId === item?.id ? 1 : 0, paddingLeft: '0px' }}
                                onClick={() => {
                                    localStorage.setItem('siteVisitQueryParams', queryParams);
                                    facilityId ? navigate(`/client/${client_id}/digitalisation/${facilityId}/site-visit-report/${item?.pid}/edit/${item?.id}`) : navigate(`/client/${client_id}/projects/site-visit-report/${item?.pid}/edit/${item?.id}`)
                                }}
                                >
                                    <Edit className="cursor-pointer"
                                        style={{ color: 'black' }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Can>
                    </Grid>

                </TableCell>
            </TableRow>
        }))
    } else {
        siteVisitReportTable = (
            <TableRow>
                <TableCell colSpan={12} align="center">
                    <h5 className='w3-text-grey'>
                        No Data Found
                    </h5>
                </TableCell>
            </TableRow>
        )
    }
    if(permissionNotFound) {
        return <Grid container >
            <Grid item md={12} className='w3-padding' style={{ display: 'flex', justifyContent: 'center', height: '500px', alignItems: 'center' }}>
                <h5 className='w3-text-grey'>
                    You don't have view or manage permission
                </h5>
            </Grid>
        </Grid>
    }
    return <>
        <Grid container sx={{ mt: 2 }}>
            <Grid container item justifyContent={'space-between'}>
                {!facilityId && (<Grid item sx={{ mb: 2 }} xs={12}>
                    <h4 className='card-title'>Site Visit {projectData?.projname && `- ${projectData?.projname}`}</h4>
                </Grid>)}
                <Can do="manage" on="AddSiteVisitReport">
                    <Grid sx={{ mb: 2 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() => 
                                facilityId ? navigate(`/client/${client_id}/digitalisation/${facilityId}/site-visit-report/create`) : navigate(`/client/${client_id}/projects/site-visit-report/${id}/create`)
                            }
                        >
                            Add Site Visit Report
                        </Button>
                    </Grid>
                </Can>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {siteVisitColumn?.map(item => (
                                    <TableCell className='font-weight-600 font-size-14 ' >{item}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {siteVisitReportTable}
                        </TableBody>
                    </Table>


                </TableContainer>
            </Grid>
            <Grid container justifyContent={'center'} className='w3-padding-16'>
                <Button
                    onClick={handleBackwardButtonClick}
                    variant="outlined"
                    style={{ width: '110px', marginRight: '4px' }}
                    startIcon={<NavigateBeforeIcon />}
                    disabled={parseInt(existingParams?.page) === 1}
                >
                    Previous
                </Button>
                <Button
                    onClick={handleForwardButtonClick}
                    variant="outlined"
                    style={{ width: '110px', justifyContent: 'flex-end', marginLeft: '4px' }}
                    endIcon={<NavigateNextIcon />}
                    disabled={siteVisitReportList?.length < itemsPerPage || siteVisitReportList?.length === 0 || !siteVisitReportList}
                >
                    Next
                </Button>
            </Grid>
                <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
                <DialogTitle><IconButton
                        aria-label="close"
                        onClick={handleCloseImageDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: 'gray',
                        }}
                    >
                        <CloseIcon />
                    </IconButton></DialogTitle>
                <DialogContent>
                    <img src={selectedImage} alt="Attachment" style={{ maxWidth: '100%' }} />
                </DialogContent>
            </Dialog>
        </Grid>

    </>
}